/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';
import LoadingSpinner from "../shared/lib/LoadingSpinner";
import {HomeLifeStrings as hlStrs} from "../shared/strings";
import PatientHeader from "../shared/PatientHeader";
import PatientDataStorage from '../DataService/DataService';
import moment from 'moment';
import Utils from "../shared/Utilities";
import {INACTIVITY_TIMEOUT} from "../shared/constants";

// import Utils from '../shared/Utilities';
const validateData = (val, postFix) => {
    postFix =  (postFix) ? postFix : '';
    if (typeof val === "string" || Number.isNaN(val)) {
        val = -1;
    }
    return val >= 0 ? val.toFixed(1) + postFix : hlStrs.notAvailable;
};

const dummyData = {
    activitiesPerAwakeHour: undefined,
    patientId: undefined,
    date: undefined,
    activitiesTotalSleepRatio: undefined,
    activitiesDuringSleep: undefined,
    gapsLong: undefined,
    gapShort: undefined,
    homeTempHi: undefined,
    homeTempLow: undefined,
    awayHours: undefined,
    kitchenActivities: undefined,
    meals: undefined,
    medicalDevices: [],
    sleepPeriods: undefined,
    sleepHours: undefined,
    totalActivities: undefined,
    userId: undefined,
    wakePeriods: undefined,
};
class HomeLifeReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            history: props.history,
            caregiverId: props.location.state.caregiverId,
            patientId: props.location.state.patientId,
            patientData: undefined,
            initDone: false,
            homeLifeData: undefined,
            timeoutId: -1,
        };
        this.handlePatientData = this.handlePatientData.bind(this);
        this.handleReportData = this.handleReportData.bind(this);
    }

    componentDidMount() {
        let {caregiverId, patientId} = this.state;

        let yesterday = new moment().subtract(1, "days");
        let pastWeek = new moment().subtract(7, "days");
        PatientDataStorage.getPatientAsync(caregiverId, patientId, this.handlePatientData);
        PatientDataStorage.getHealthReport(caregiverId, patientId,
                pastWeek.format("YYYY-MM-DD"), yesterday.format("YYYY-MM-DD"),
                this.handleReportData);
    }

    componentWillUnmount() {
        console.log('componentWillUnmount: HomeLife Record');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
    }

    updateTimeout() {
        console.log('HomeLife Record timeout update');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
        let timeoutId = setTimeout( () => {
            Utils.cleanupGoToLogin(this.state.history, true);
        }, INACTIVITY_TIMEOUT);

        PatientDataStorage.keepAlive();
        return timeoutId;
    }

    handleReportData(healthReport, success) {
        let newState = {};
        if (success === 200) {
            newState = {homeLifeData: healthReport};
            if (this.state.patientData) {
                newState.initDone = true;
            }
        } else {
            if (success === 401) {
                this.patientReset(success);
            }
            newState = {error: "error", homeLifeData: undefined};
            if (this.state.patientData) {
                newState.initDone = true;
            }
        }
        newState.timeout = this.updateTimeout();
        this.setState(newState);
    }

    patientReset(status) {
        if (status === 401) {
            if (!this.state.error) {
                this.setState({error: true});
                Utils.cleanupGoToLogin(this.state.history);
            }
        } else {
            alert('error');
        }
    }

    // patient overview data arrived
    handlePatientData( pData, success ) {
        if (success === 200) {
            let newState = {patientData: pData};
            if (this.state.homeLifeData) {
                newState.initDone = true;
            }

            newState.timeoutId = this.updateTimeout();
            this.setState(newState);
        } else {
            this.patientReset(success);
        }
    }

    render() {
        let {patientData: pData, initDone} = this.state;

        let spinner = (initDone) ? "" : (<div><LoadingSpinner /></div> );
        let headerText = <span>{hlStrs.title}&trade;</span>;
        let homeLifeReport = '';
        if (initDone) {
            if (this.state.homeLifeData !== undefined) {
                try {
                    homeLifeReport = this.buildReport();
                } catch(e) {
                    homeLifeReport = (<div className="error-block">{hlStrs.serverError}</div>);
                }
            } else {
                homeLifeReport = (<div  className="error-block">{hlStrs.serverError}</div>);
            }
        }

        let header = (pData) ? (
            <div>
                <PatientHeader patientName={pData.patientName}
                               patientImage={pData.patientImage}
                               patientState={pData.patientState}
                               bodyContent={headerText}
                               history={this.state.history}
                />
            </div>
        ) : "";

        return (
            <div id="home-life-report">
                {header}
                {homeLifeReport}
                {spinner}
            </div>
        )
    }

    buildReport() {
        const { homeLifeData } = this.state;
        const { daysData, standardDevs, averages } = homeLifeData;
        let keyCount = 0;

        // put the data in the correct order for iterating over it.
        let data = [];
        for (let i=daysData.length-1; i >= 0; i--) {
            let day = daysData[i];
            if (day.error === undefined) {
                data.push(day);
            } else {
                let badDay = {...dummyData};
                badDay.date = day.date;
                data.push(badDay);
            }
            if (i === daysData.length-1) {
                data.push(averages);
                data.push(standardDevs);
            }
        }

        let mealData = this.getMealsData(keyCount, data);
        let sleepData = this.getSleepData(keyCount, data);
        let activityData = this.getActivityData(keyCount, data);
        let temperatureData = this.getTemperatureData(keyCount, data);
        let medDevices = this.getMedicalDevices();
        let medData = this.getMedData(keyCount, medDevices, data);

        let days = [];
        let mostRecentDay = daysData[daysData.length-1].date;
        let mostRecentDisplayDate = new moment(mostRecentDay).format('ddd MMM D, YYYY');
        days.push(`${hlStrs.day}(${mostRecentDisplayDate})`);
        for (let i=daysData.length-2; i >= 0; i--) {
            let day = daysData[i];
            let friendlyDay = new moment(day.date).format('ddd');
            days.push(friendlyDay);
        }

        let report = (
            <div className="hlr">
                <div className="hlr-grid">
                    <div className="hlr-header1 header">
                        <div className="header-spacer">&nbsp;</div>
                        <div className="recent-day-header"><div>{days[0]}</div></div>
                        <div className="prev-week-header">{hlStrs.priorWeek}</div>
                        <div>{days[1]}</div>
                        <div>{days[2]}</div>
                        <div>{days[3]}</div>
                        <div>{days[4]}</div>
                        <div>{days[5]}</div>
                        <div>{days[6]}</div>
                    </div>
                    <div className="hlr-header2">
                        <div>{hlStrs.avg}</div>
                        <div>{hlStrs.stDev}</div>
                        <div>{hlStrs.oneDPrior}</div>
                        <div>{hlStrs.twoDPrior}</div>
                        <div>{hlStrs.threeDPrior}</div>
                        <div>{hlStrs.fourDPrior}</div>
                        <div>{hlStrs.fiveDPrior}</div>
                        <div>{hlStrs.sixDPrior}</div>
                    </div>
                    {sleepData}
                    {mealData}
                    {activityData}
                    {temperatureData}
                    {medData}
                </div>
            </div>
        );

        return report;
    }

    getSleepData(keyCount, data) {
        let sectionStart = (
            <div className="sleep" key="sleep">
                <div key={''+keyCount++}>{hlStrs.sleeping}</div>
            </div>
        );

        let sleepHours = [sectionStart, <div  key={'s'+keyCount++}>{hlStrs.numHours}</div>];
        let sleepShort = [<div key={'gs'+keyCount++}>{hlStrs.gapsShort}</div>];
        let sleepLong = [<div key={'gl'+keyCount++}>{hlStrs.gapsLong}</div>];
        let sleepPeriods = [<div key={'np'+keyCount++}>{hlStrs.numPeriods}</div>];

        for (let i=0, len=data.length; i < len; i++) {
            sleepHours.push(<div key={'sld'+keyCount++}>{validateData(data[i].sleepHours)}</div>);
            sleepShort.push(<div key={'gsd'+keyCount++}>{validateData(data[i].sleepGapsShort)}</div>);
            sleepLong.push(<div key={'gld'+keyCount++}>{validateData(data[i].sleepGapsLong)}</div>);
            sleepPeriods.push(<div key={'npd'+keyCount++}>{validateData(data[i].sleepPeriods)}</div>);
        }
        return (
            <div className="sleep-block">
                {sleepHours}
                {sleepShort}
                {sleepLong}
                {sleepPeriods}
            </div>
        );
    }

    getMealsData(keyCount, data) {
        let sectionStart = (
            <div key={'eating'+keyCount++} className="eating">
                <div>{hlStrs.eating}</div>
            </div>
        );

        let numMeals = [sectionStart, <div key="nummeals">{hlStrs.meal}</div>];
        let kitchenScore = [<div key={"kblock"+keyCount++}>{hlStrs.kitchenActivity}</div>];

        for (let i=0, len=data.length; i < len; i++) {
            numMeals.push(<div key={'m'+keyCount++}>{validateData(data[i].meals)}</div>);
            kitchenScore.push(<div key={'ks'+keyCount++}>{validateData(data[i].kitchenActivities)}</div>);
        }
        return (
            <div className="eating-block">
                {numMeals}
                {kitchenScore}
            </div>
        );
    }

    getActivityData(keyCount, data) {
        let sectionStart = (
            <div className="activity" key={'a'+keyCount++}>
                <div>{hlStrs.activity}</div>
            </div>
        );

        let total = [sectionStart, <div key={'t'+keyCount++}>{hlStrs.total}</div>];
        let activitiesDuringSleep = [<div key={'ds'+keyCount++}>{hlStrs.activitiesDuringSleep}</div>];
        let hrsAway = [<div key={'ha'+keyCount++}>{hlStrs.hrsAwayFromHome}</div>];
        let actHome = [<div key={'ah'+keyCount++}>{hlStrs.activityHomeWaking}</div>];
        let activitiesTotalSleepRatio = [<div key={'dn'+keyCount++}>{hlStrs.activitiesTotalSleepRatio}</div>];

        const roundValue = (value) => {
            if (!Number.isNaN(value)) {
                value = Math.floor(value+.5);
            } else {
                value = -1;
            }
            return value;
        };
        for (let i=0, len=data.length; i < len; i++) {
            const activity = data[i];
            let ratio = roundValue(activity.activitiesTotalSleepRatio);
            let activityPerHr = roundValue(activity.activitiesPerAwakeHour);

            total.push(<div key={'a'+keyCount++}>{validateData(activity.totalActivities)}</div>);
            activitiesDuringSleep.push(<div key={'a'+keyCount++}>{validateData(activity.activitiesDuringSleep)}</div>);
            hrsAway.push(<div key={'a'+keyCount++}>{validateData(activity.awayHours)}</div>);
            actHome.push(<div key={'a'+keyCount++}>{validateData(activityPerHr)}</div>);
            activitiesTotalSleepRatio.push(<div key={'a'+keyCount++}>{validateData(ratio)}</div>);
        }
        return (
            <div className="activity-block">
                {total}
                {activitiesDuringSleep}
                {hrsAway}
                {actHome}
                {activitiesTotalSleepRatio}
            </div>
        );
    }
    getTemperatureData(keyCount, data) {
        let sectionStart = (
            <div className="temp" key={'tmp'+keyCount++}>
                <div>{hlStrs.homeTemp}</div>
            </div>
        );

        let tempHi = [sectionStart, <div key={'th'+keyCount++}>{hlStrs.high}</div>];
        let tempLow = [<div key={'tl'+keyCount++}>{hlStrs.low}</div>];

        for (let i=0, len=data.length; i < len; i++) {
            tempHi.push(<div key={'th'+keyCount++}>{validateData(data[i].homeTempHi)}</div>);
            tempLow.push(<div key={'tl'+keyCount++}>{validateData(data[i].homeTempLow)}</div>);
        }

        return (
            <div className="temp-block">
                {tempHi}
                {tempLow}
            </div>
        );
    }
    getMedicalDevices() {
        const { homeLifeData } = this.state;
        let existingDevices = {};
        let devList = [];
        for (let i=0, len=homeLifeData.daysData.length; i < len; i++) {
            let day = homeLifeData.daysData[i];
            if (!day.medicalDevices || day.medicalDevices.length === 0) {
                continue;
            }

            for (let j=0, jlen=day.medicalDevices.length; j < jlen; j++) {
                let device = day.medicalDevices[j];
                let devName = device.devName.trim();
                if (existingDevices[devName] === undefined) {
                    let metricNames = device.devValues.map(item => item.valueName);
                    existingDevices[devName] = '';
                    devList.push({name: devName, metricNames});
                }
            }
        }
        return devList;
    }

    medRowBuilder(keyCount, medDevice) {
        let regex = /_/gi;
        let brandIdx = medDevice.name.indexOf(' ');

        return {
            currentMetric: 0,
            metricNames: medDevice.metricNames,
            name: medDevice.name.trim(),
            nextRow: function() {
                let nameBlock = undefined;
                let metricName = undefined;
                if (this.currentMetric < this.metricNames.length) {
                    let block1 = (this.currentMetric === 0) ?
                        <div key={'m'+keyCount++}>{this.name.substring(brandIdx)}</div> : <div></div>;
                    metricName = this.metricNames[this.currentMetric].trim();
                    const metricLabel = metricName.replace(regex, ' ');
                    nameBlock = ( <div key={'m'+keyCount++}>{block1}<div key={'m'+keyCount++}>{metricLabel}</div></div> );
                    this.currentMetric++;
                }
                return {nameBlock, metricName, name: this.name};
            }
        }
    }

    getMedData(keyCount, medDevices, data) {
        let medData = [];
        if (medDevices.length !== 0) {
            for (let i=0, len=medDevices.length; i < len; i++) {
                let rowBlder = this.medRowBuilder(keyCount, medDevices[i]);
                let nextDevMetric = rowBlder.nextRow();
                while (nextDevMetric.nameBlock !== undefined) {
                    medData.push(nextDevMetric.nameBlock);
                    const metricName = nextDevMetric.metricName;
                    const deviceName = nextDevMetric.name;
                    for (let j = 0, jLen = data.length; j < jLen; j++) {
                        const dayMedData = data[j].medicalDevices;
                        let device = dayMedData.find(item =>
                            item.devName.trim() === deviceName);
                        let value = hlStrs.notAvailable;
                        if (device) {
                            value = device.devValues.find(item =>
                                item.valueName.trim() === metricName);
                        }
                        let theValue = (value.value === undefined) ? '-' : Math.floor(value.value+.5);
                        medData.push(<div key={'mdr'+keyCount++}>{theValue}</div>)
                    }
                    nextDevMetric = rowBlder.nextRow();
                }
            }
            return (
                <div key={'rxdata'} className="med-block">
                    <div className="med"><div>{hlStrs.medicalDevices}</div></div>
                    {medData}
                </div>
            )
        } else {
            return '';
        }
    }
}

export default HomeLifeReport;
