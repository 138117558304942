/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';
import PatientDataStorage from '../DataService/DataService';
import PatientHeader from '../shared/PatientHeader';
import { BatteryLevelStrings } from '../shared/strings';
import LoadingSpinner from '../shared/lib/LoadingSpinner';
import Utils from '../shared/Utilities';
import {INACTIVITY_TIMEOUT} from "../shared/constants";
import moment from 'moment-timezone';

class BatteryLevelsReport extends Component {
    constructor(props) {
        super(props);

    //console.log("BatteryLevelsReport props: "+JSON.stringify(props));

       let eventDate = new moment.tz(props.location.state.timezone);
       let dateTime = Utils.convertMomentToTimeData(eventDate, true);

        this.state = {history: props.history,
            initDone: false,
            caregiverId: props.location.state.caregiverId,
            patientId: props.location.state.patientId,
            timezone: props.location.state.timezone,
            batteryReportTime: dateTime,
            battLevelsData: undefined,
            timeoutId: -1
        };

        this.handlePatientData = this.handlePatientData.bind(this);
        this.handleBatteryData = this.handleBatteryData.bind(this);
    }

    componentDidMount() {
        let {caregiverId, patientId} = this.state;

        PatientDataStorage.getPatientAsync(caregiverId, patientId, this.handlePatientData);
        PatientDataStorage.getChargeLevels(patientId, this.handleBatteryData);
    }

    componentWillUnmount() {
        console.log('componentWillUnmount: BatteryLevelReport');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
    }

    updateTimeout() {
        console.log('Battery Level Report timeout update');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
        let timeoutId = setTimeout( () => {
            Utils.cleanupGoToLogin(this.state.history, true);
        }, INACTIVITY_TIMEOUT);
        PatientDataStorage.keepAlive();
        return timeoutId;
    }

    handlePatientData(pData, success) {
        if (success === 200) {
            let state = {patientData: pData};
            if (this.state.battLevelsData) {
                state.initDone = true;
            }

            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    handleBatteryData(battLevelsData, success) {
        if (success === 200) {
            let state = {battLevelsData: battLevelsData};
            if (this.state.patientData) {
                state.initDone = true;
            }

            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    patientReset(success) {
        if (success === 401) {
            if (!this.state.error) {
                this.setState({error: true});
                Utils.cleanupGoToLogin(this.state.history);
            }
        } else {
            alert('bad data from server');
        }
    }

    buildBatteryTable(battLevelsData) {
        //console.log("buildBatteryTable battLevelsData: "+JSON.stringify(battLevelsData));

        let mtoday = new moment.tz(this.state.timezone);
        let today = Utils.convertMomentToTimeData(mtoday, true);

        let rows = battLevelsData.batteryLevels.map((batteryLevel, i) => {
            let timeData = Utils.convertISO8601wTZToTimeData(batteryLevel.eventData, true);
             let displayDate;
            if (timeData.datestring === today.datestring) {
                displayDate = Utils.timeDataToDisplayString(timeData);
            } else {
                displayDate = Utils.timeDataToDisplayDateTime(timeData);
            }

            return (
                <tr key={i}>
                    <td>{batteryLevel.sensorName}</td>
                    <td>{batteryLevel.batteryLevel}%</td>
                    <td>{displayDate}</td>
                </tr>
            )
        });

        return (
            <table className="table">
                <thead>
                <tr>
                    <th>{BatteryLevelStrings.deviceName}</th>
                    <th>{BatteryLevelStrings.chargeLevel}</th>
                    <th>{BatteryLevelStrings.lastEvent}</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    render() {
        let {patientData: pData, initDone, battLevelsData, batteryReportTime} = this.state;

        //let pData = this.state.patientData;
        let spinner = (initDone) ? "" : (<div><LoadingSpinner /></div> );
        let headerText = BatteryLevelStrings.title;
        let header = (pData) ? (
            <PatientHeader patientName={pData.patientName}
                           patientImage={pData.patientImage}
                           patientState={pData.patientState}
                           bodyContent={headerText}
                           history={this.state.history}
            />
        ) : "";

        let tbl = (initDone) ? this.buildBatteryTable(battLevelsData) : "";

        return (
            <div id="bLevelReport">
                {header}
                <div>{BatteryLevelStrings.levelAsOf} {batteryReportTime.datestring}</div>
                {spinner}
                {tbl}
            </div>
        )
    }
}

export default BatteryLevelsReport;
