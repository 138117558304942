import React, { Component } from 'react';

class CareGiverProfile extends Component {
    render() {
        return (
            <div className="MPY-caregiver-padding">
                <h3>Caregiver Profile</h3>
            </div>
        );
    }
}

export default CareGiverProfile;
