/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';
import { NextPrevNavStrings } from '../strings';
import Button from 'react-bootstrap/Button';

class NextPrevNav extends Component {
    constructor(props) {
        super(props);
        const cmdObj = props.cmdObj;
        const showPrevNext = (cmdObj.nextBtnEnabled !== undefined && cmdObj.prevBtnEnabled !== undefined);
        if (!showPrevNext) {
            console.log("NextPrevNav: not showing next or prev button. title = " + props.title);
        }

        this.state = {
            icon: props.iconClass,
            title: props.title,
            nextHandler: cmdObj.nextHandler,
            prevHandler: cmdObj.prevHandler,
            nextBtnLabel: cmdObj.nextBtnLabel ? cmdObj.nextBtnLabel : NextPrevNavStrings.next,
            prevBtnLabel: cmdObj.prevBtnLabel ? cmdObj.prevBtnLabel : NextPrevNavStrings.prev,
            nextBtnEnabled: showPrevNext ? cmdObj.nextBtnEnabled : undefined,
            prevBtnEnabled: showPrevNext ? cmdObj.prevBtnEnabled : undefined,
            showPrevNext: showPrevNext
        };
        this.handleNextBtn = this.handleNextBtn.bind(this);
        this.handlePrevBtn = this.handlePrevBtn.bind(this);
    }

    componentDidUpdate(prevProps) {

        let newState = {};
        let update = false;
        if (prevProps.cmdObj.nextBtnEnabled !== this.props.cmdObj.nextBtnEnabled) {
            newState.nextBtnEnabled = this.props.cmdObj.nextBtnEnabled;
            update = true;
        }
        if (prevProps.cmdObj.prevBtnEnabled !== this.props.cmdObj.prevBtnEnabled) {
            newState.prevBtnEnabled = this.props.cmdObj.prevBtnEnabled;
            update = true;
        }
        if (update) {
            this.setState(newState);
        }
    }
    handleNextBtn() {
        if (this.state.nextHandler) {
            (this.state.nextHandler)();
        }
    }
    handlePrevBtn() {
        if (this.state.prevHandler) {
            (this.state.prevHandler)();
        }
    }

    buildBtns( handler, label, enabled) {

        let btn = "";
        if (enabled) {
            btn = <div><Button variant="link" alt={label} onClick={handler}>{label}</Button></div>;
        } else {
            btn = <div><Button variant="link" alt={label} disabled>{label}</Button></div>;
        }

        return btn;
    }

    render() {
        let {iconClass, title, showPrevNext } = this.state;

        let btns = "";
        if (showPrevNext) {
            let { nextBtnLabel, nextBtnEnabled,
                  prevBtnLabel, prevBtnEnabled } = this.state;
            let prevBtn = this.buildBtns(this.handlePrevBtn, prevBtnLabel, prevBtnEnabled);
            let nextBtn = this.buildBtns(this.handleNextBtn, nextBtnLabel, nextBtnEnabled);

            btns = (
                <div>
                    <i className="fa fa-chevron-left" aria-hidden="true"> </i>
                    {prevBtn} | {nextBtn}
                    <i className="fa fa-chevron-right" aria-hidden="true"> </i>
                </div>
            );
        }

        const classname = "next-prev-btn-nav " + iconClass;
        return (
            <div className={classname}>
                <span>{title}</span>
                {btns}
            </div>
        );
    }
}

export default NextPrevNav;
