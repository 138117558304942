/* eslint-disable react/prop-types */
/*******
 * Copyright 2024 - EmPowerYu, inc.
 *
 */
import React, {Component} from 'react';
import Button from "react-bootstrap/Button";
import PatientHeader from "../shared/PatientHeader";
import PatientDataStorage from "../DataService/DataService";
import LoadingSpinner from '../shared/lib/LoadingSpinner';
import { LocationAlertOptionsStrings } from '../shared/strings';
import Utils from "../shared/Utilities";

class LocAlertOptions extends Component {
    constructor(props) {
        super(props);

        //console.log("LocAlertOptions props: "+JSON.stringify(props));

        this.state = {
            history: props.history,
            initDone: false,
            caregiverId: props.location.state.caregiverId,
            patientId: props.location.state.patientId,
            armedData: undefined,
            drawCount: 0
        }

        this.handlePatientData = this.handlePatientData.bind(this);
        this.handleArmedData = this.handleArmedData.bind(this);
    }

    componentDidMount() {
        let {caregiverId, patientId} = this.state;

        PatientDataStorage.getPatientAsync(caregiverId, patientId, this.handlePatientData);
        PatientDataStorage.getLocationAlertArmed(patientId, this.handleArmedData);
    }

    patientReset(status) {
        if (status === 401) {
            Utils.cleanupGoToLogin(this.state.history);
        } else {
            alert('error');
        }
    }

    // patient overview data arrived
    handlePatientData( pData, success ) {
        console.log(`handlePatientData called.`);
        if (success === 200) {
            let state = {patientData: pData};
            if (this.state.armedData) {
                state.initDone = true;
            }
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    handleArmedData(armedData, success) {
        console.log(`handleArmedData called.`);
        if (success === 200) {
            let state = {armedData: armedData};
            if (this.state.patientData) {
                state.initDone = true;
            }
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }
    
    render() {
        let {patientData: pData, initDone, armedData, history} = this.state;

        let spinner = (initDone) ? "" : (<div><LoadingSpinner /></div> );
        let headerText = LocationAlertOptionsStrings.title;
        let header = (pData) ? (
            <PatientHeader patientName={pData.patientName}
                           patientImage={pData.patientImage}
                           patientState={pData.patientState}
                           bodyContent={headerText}
                           history={history}
            />
        ) : "No header";

        let headertext = LocationAlertOptionsStrings.header;

        let  buttonText = (armedData) ? ((armedData.armed) ? 
            LocationAlertOptionsStrings.buttonTextOn 
          : LocationAlertOptionsStrings.buttonTextOff) : "";

        return (
            <div>
                {header}
                {spinner}
                <p><b>{headertext}</b></p>
                <Button onClick={() => {this.doSubmitChange(armedData)}}  size="sm" alt="submit button">
                    {buttonText}
                </Button>
            </div>
        );
    }

    doSubmitChange(armedData) {
        
        console.log("Submit button pushed.");

        const state = {armedData: armedData};
        let armed = !armedData.armed;

        armedData.armed = armed;

        this.setState(state);

        PatientDataStorage.setLocationAlertArmed(this.state.patientId, armed, this.handleUpdateOptions);
    }

    // this is called by the promise when the submit completes
    handleUpdateOptions(success) {
        if (success === 401) {
            console.log('Options:  Failed to save options with 401.');
            this.patientReset(success);
        } else if (success !== 200) {
            console.log('Options:  Failed to save options.');
        } else {
            console.log(' Options:  Save success.');
        }
    }
}

export default LocAlertOptions;
