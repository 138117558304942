/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */
import React, { Component } from 'react';
import moment from 'moment-timezone';
import {HeatMapStrings} from '../shared/strings';
import {DAYOFWEEKMAP} from '../shared/constants';
import Utils from '../shared/Utilities';
import caduceus from '../images/caduceus-medical-symbol.svg';
import caduceusDisabled from '../images/caduceus-medical-symbol-disabled.svg';

class HMTop extends Component {
    constructor(props) {
        super(props);
        this.state = {mapType: props.mapType};
    }

    render() {
        return (
            <tr cellPadding='0' cellSpacing='0'>
                <th colSpan='6'>12am</th>
                <th colSpan='6'>3</th>
                <th colSpan='6'>6</th>
                <th colSpan='6'>9</th>
                <th colSpan='6'>Noon</th>
                <th colSpan='6'>3</th>
                <th colSpan='6'>6</th>
                <th colSpan='6'>9pm</th>
            </tr>
    );
    }
}

class HMapDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hmapData: props.data,
            legend: props.legend,
            mapType: props.mapType
        };
    }

    componentDidUpdate(prevProps) {
        // let oldDate = prevProps.data.date;
        // let hmDate = this.props.data;
        // let data = this.props.data;
        // if (oldDate !== hmDate.date) {
        //     this.setState({date: data.date, hmHourlyData: data.hmHourlyData});
        // }
        let oldDate = prevProps.data.date;
        let hmDate = this.props.data.date;

        let data = this.props.data;
        data.hmHourlyData.rxDevices = data.hmHourlyData.rxDevices ? data.hmHourlyData.rxDevices : [];
        data.hmHourlyData.devices = data.hmHourlyData.devices ? data.hmHourlyData.devices : [];
        if (oldDate !== hmDate) {
            this.setState({hmapData: data});
        }
    }

    buildDeviceRows() {
        let legend = this.state.legend;
        let devices = this.state.hmapData.hmHourlyData.devices;
        let devrows = [];

        for (let i=0, len=devices.length; i < len; i++) {
            let dev = devices[i];
            let color = "#" + legend.deviceObj[dev.deviceId].rgbColor;
            let devRow = [];

            for (let j=0, len2= dev.deviceData.length; j < len2; j++) {
                let hours = dev.deviceData[j];
                let cell;
                switch(hours) {
                    case 0:
                        cell = (<td key={"d"+j} className="hmNoData"> </td>);
                        break;
                    case 1:
                        cell = (<td key={"d"+j} className="hmBgd"> </td>);
                        break;
                    case 2:
                        cell = (<td key={"d"+j} style={{background: color}}> </td>);
                        break;
                    default:
                        console.log("unknown value for device.");
                        cell = "";
                        break;
                }
                devRow.push(cell);
            }
            let row = ( <tr className="dev-tr" key={"drow"+i}>{devRow}</tr> );
            devrows.push(row);
        }

        return devrows;
    }

    buildRxDeviceRows() {
        let legend = this.state.legend;
        let devices = this.state.hmapData.hmHourlyData.rxDevices;
        let devrows = [];

        for (let i=0, len=devices.length; i < len; i++) {
            let dev = devices[i];
            let color = "#" + legend.rxDeviceObj[dev.deviceId].rgbColor;
            let devRow = [];

            for (let j=0, len2= dev.deviceData.length; j < len2; j++) {
                let hours = dev.deviceData[j];
                let cell;
                switch(hours) {
                    case 0:
                        cell = (<td key={"d"+j} className="hmNoData"> </td>);
                        break;
                    case 1:
                        cell = (<td key={"d"+j} className="hmBgd"> </td>);
                        break;
                    case 2:
                        cell = (<td title={HeatMapStrings.RxDevGoodValToolTip} key={"d"+j} className="rxBorderGood" style={{background: color}}> </td>);
                        break;
                    case 3:
                        cell = (<td title={HeatMapStrings.RxDevBadValToolTip} key={"d"+j} className="rxBorderBad" style={{background: color}}> </td>);
                        break;
                    default:
                        console.log("unknown value for device.");
                        cell = "";
                        break;
                }
                devRow.push(cell);
            }
            let row = ( <tr className="dev-tr" key={"drow"+i}>{devRow}</tr> );
            devrows.push(row);
        }

        return devrows;
    }

    buildSpacerRow(name) {
        return ( <tr className="hmSpacerRow" key={name}><td colSpan="48"><hr /></td></tr> );
    }

    buildHmapRow() {
        let {hmapData, mapType} = this.state;

        // update the labelFunction for the heatmap type if you want to change the data
        // that is displayed when the mouse hovers over the heatmap cell
        let mapTypeInfo = {
            activity: {
                classPrefix: "hm-act",
                labelFunction: function(label) { return (label) ? HeatMapStrings.minutesActive + label : ""; } },
            meals: {
                classPrefix: "hm-meal",
                labelFunction: function(label) { return label ? label : "" } },
            sleeping: {
                classPrefix: "hm-slp",
                labelFunction: function() { return ""; }
            }
        };

        let hmapInfo = mapTypeInfo[mapType];

        let row = [];
        if (hmapInfo) {
            row = hmapData.hmHourlyData.hmData.map((slot, i) => {
                let name = hmapInfo.classPrefix + slot.hmElem;
                let label = hmapInfo.labelFunction(slot.label);
                let outline = (slot.oline === 1) ? (<div className="hm-outline"> </div>): "";
                return (
                    <td key={i} className={name} title={label}>{outline}</td>
                );
            });
        } else {
            console.log( "Bad maptype passed to headmap");
            row = (<td key="badMapType"> </td>)
        }

        return row;
    }

    render() {
        let rows = [];

        let maprow = this.buildHmapRow();
        let row = (
            <tr key="main">{maprow}</tr>
        );

        rows.push(row);

        let deviceRows = this.buildDeviceRows();
        let blank = "";
        if (deviceRows.length > 0) {
            blank = this.buildSpacerRow("rxdevspacer");
            rows.push(blank);
            rows.push(deviceRows);
        }

        deviceRows = this.buildRxDeviceRows();
        blank = "";
        if (deviceRows.length > 0) {
            blank = this.buildSpacerRow("devspacer");
            rows.push(blank);
            rows.push(deviceRows);
        }

        return (
            <tbody>
            {rows}
            </tbody>
        );
    }
}

class HeatMap extends Component {
    constructor(props) {
        super(props);

        let hmapData = props.hmapData;
        if (hmapData.otherData !== undefined && typeof hmapData.otherData !== "object") {   // todo: remove hack
            hmapData.otherData = { threshold: hmapData.otherData}                           // todo: remove hack
        }
        //console.log("HeatMap.Const(): "+hmapData.date.datetime + " " + hmapData.date.tz);
        hmapData.hmHourlyData.rxDevices = hmapData.hmHourlyData.rxDevices ? hmapData.hmHourlyData.rxDevices : [];
        hmapData.hmHourlyData.devices = hmapData.hmHourlyData.devices ? hmapData.hmHourlyData.devices : [];
        let dateString = this.buildDateString( hmapData.date, hmapData.otherData );

        this.state = {
            hmapData: props.hmapData,
            mapType: props.type,
            dateString: dateString,
            legendData: props.legendData,
            clickHandler: props.clickHandler
        };

        this.handleCaduceusClick = this.handleCaduceusClick.bind(this);
    }

    buildDateString(iso8601Array, otherData) {
        const hmMoment = new moment.tz(iso8601Array.datetime, iso8601Array.tz);
        const mapDateArray = Utils.convertISO8601wTZToTimeData(iso8601Array);
        let dateString = "";

        let mapDateString = mapDateArray.datestring;
        if (mapDateString.length === 0) {
            dateString = HeatMapStrings.today;
        } else {
            if (this.isDateYesterday(iso8601Array)) {
                dateString = HeatMapStrings.yesterday;
            } else {
                // isoWeekday returns a value between 1 and 7. We want 0 and 6.
                let dayOfWeek = DAYOFWEEKMAP[hmMoment.isoWeekday()-1];
                dateString = mapDateString + " " + dayOfWeek;
            }
        }

        // if HMap header changes for a heatmap, update the following code.
        let tstring;
        if (otherData && otherData.threshold) {
            tstring = HeatMapStrings.threshold + otherData.threshold.toFixed(2);
            dateString = <span title={tstring} >{dateString}</span>;
        } else if (otherData && otherData.minutesActiveTotal) {
            tstring = HeatMapStrings.totalActivity+ otherData.minutesActiveTotal +
                HeatMapStrings.sleepActivity + otherData.minutesActiveSleeping;
            dateString = <span title={tstring} >{dateString}</span>;
        }

        return dateString;
    }

    isDateYesterday(iso8601Array) {
        let rv = false;
        let yesterdaydate = new moment.tz();
        yesterdaydate.tz(iso8601Array.tz);
        yesterdaydate.subtract(1, 'day');

        let mapDate = new moment.tz(iso8601Array.datetime, iso8601Array.tz);

        if (yesterdaydate.isSame(mapDate, 'year') && 
            yesterdaydate.isSame(mapDate, 'month') && 
            yesterdaydate.isSame(mapDate, 'day')) {
            rv = true;
        }

        return rv;
    }

    componentDidUpdate(prevProps) {
        let oldHmapData = prevProps.hmapData;
        let hmapData = this.props.hmapData;
        if (oldHmapData.date !== hmapData.date) {
            if (hmapData.otherData !== undefined && typeof hmapData.otherData !== "object") {   // todo: remove hack
                hmapData.otherData = { threshold: hmapData.otherData}                           // todo: remove hack
            }
            let dateString = this.buildDateString(hmapData.date, hmapData.otherData);
            this.setState({dateString: dateString, hmapData: hmapData});
        }
    }

    handleCaduceusClick() {
        let {clickHandler, hmapData} = this.state;
        (clickHandler)(hmapData.date);
    }

    render() {
        const {hmapData, legendData, dateString, mapType} = this.state;

        let map;
        let rxSymbol = "";

        map = <HMapDetail data={hmapData} legend={legendData} mapType={mapType} />;

        let rxDevices = hmapData.hmHourlyData.rxDevices;
        if (rxDevices && rxDevices.length !== 0) {
            // we know we want to show caduceaus icon but do we show the enabled icon (there is medical
            // data for today) or the disabled icon
            let goodRxData = false;

            for (let i = 0, len = rxDevices.length; i < len; i++) {
                let device = rxDevices[i];
                if (device.deviceData.some(rxdata => {
                        return (rxdata > 1)
                    })) {
                    goodRxData = true;
                    break;
                }
            }
            if (goodRxData) {
                rxSymbol =
                    <div onClick={this.handleCaduceusClick} className="caduceus">
                        <img src={caduceus} alt="caduceus"/>
                    </div>;
            } else {
                rxSymbol = <div className="caduceusDisabled"><img src={caduceusDisabled} title={HeatMapStrings.noRxData} alt={HeatMapStrings.noRxData} /></div>;
            }
        }

        return (
            <div className='heatmap'>
                <div>{dateString}{rxSymbol}</div>
                <div>
                    <table>
                        {map}
                        <tfoot>
                            <HMTop mapType={this.state.mapType}/>
                        </tfoot>
                    </table>
                </div>
            </div>)
    }
}

export default HeatMap;
