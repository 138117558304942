/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';
import Utils from '../shared/Utilities';
import TimeControl from '../shared/lib/TimeControl';
import EditCtrlWValidator from '../shared/lib/EditCtrlWValidator';
import {OptionsPageString as STRs} from '../shared/strings';

const _numDOW = 7;

class AdvancedOptions extends Component {
    constructor(props) {

        super(props);
        let validationInfo = props.validationInfo;
        let advOptions = props.advOptions;

        let modalData = {
            validationInfo: props.validationInfo,
            advOptions: advOptions,
            strings: props.strings,
            aoHandler: props.aoHandler,
        };

        if (validationInfo.validation === "T") {
            modalData.time = advOptions.value1; 
        }
        this.state = modalData;

        this.handleRadioClick = this.handleRadioClick.bind(this);
        this.handleEditChange = this.handleEditChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handlePercentageChange = this.handlePercentageChange.bind(this);
        this.toggleTimeCheckbox = this.toggleTimeCheckbox.bind(this);
    }

    handleRadioClick(e) {
        let data = this.state.advOptions;
        let vInfo = this.state.validationInfo;

        data.useDefault = (e.target.value === 'default' );

        if (data.useDefault && vInfo.validation === "T") {
            data.value1 = data.value1.map( () => {
                return  "";
            });
        }
        this.setState({advOptions: data});
        this.state.aoHandler(data);
    }

    handleEditChange(e) {
        let data = this.state.advOptions;

        if (e.target.name === 'value1' ) {
            data.value1 = e.target.value;
        } else {
            data.value2 = e.target.value;
        }

        data.useDefault = false;
        this.setState({advOptions: data});
        this.state.aoHandler(data);

    }

    handleTimeChange(time, timeId) {
        let data = this.state.advOptions;
        data.useDefault = false;
        data.value1[timeId] = time;

        this.setState({advOptions: data});
        this.state.aoHandler(data);
    }

    toggleTimeCheckbox(evt) {
        let dow = Number.parseInt(evt.target.value,10);

        let currentAOData = this.state.advOptions;
        let data = currentAOData.value1[dow];

        currentAOData.useDefault = false;
        let isDefault = evt.target.checked;

        // if checkbox is no longer checked (i.e., not default) then it use to be default.  So for the value
        // we'll use the default value from validation info.
        data = (isDefault) ? "" : this.state.validationInfo.value1[dow];

        currentAOData.value1[dow] = data;
        this.setState({advOptions: currentAOData, time: currentAOData.value1});
        this.state.aoHandler(currentAOData);
    }

    handlePercentageChange(value) {
        let data = this.state.advOptions;
        data.value1 = value;
        data.useDefault = false;
        this.setState({advOptions: data});
        this.state.aoHandler(data);
    }

    makeDataHandler(params, validationInfo, useDefault) {
        let inputFields;
        let param = params[0];

        const mpyuControl =  <p>{param.label}</p>;

        inputFields = this.buildParameterControl(params, validationInfo);

        return (
            <div id="input-control">
                <div key="rad_ic1"><input type="radio" name="defaults" checked={useDefault} value="default" key="1" onChange={this.handleRadioClick} /></div>
                <div key="data_ic1"><label htmlFor="defaults">{mpyuControl}</label></div>
                <div key="rad_ic2"><input name="custom" type="radio" checked={!useDefault} value="custom" key="2" onChange={this.handleRadioClick}/></div>
                <div key="data_ic2"><label htmlFor="custom">{inputFields}</label></div>
            </div>
        );
    }

    buildParameterControl(params, validationInfo) {
        let inputFields =[];
        let markup = undefined;

        switch (validationInfo.validation) {
            case "T":
                markup = this.buildTimeTable(params[1], validationInfo);
                inputFields.push(markup);
                break;

            case "P":
            case "TP": 
                markup = this.build_P_TP_markup(params, validationInfo)
                inputFields.push(markup);
                break;

            default: 
            case "VTR":
            case "EMV": {
                inputFields.push(this.buildEditCtrl(params[1], "value1", "2"))

                if (params.length > 2) {
                    inputFields.push(this.buildEditCtrl(params[2], "value2", "3"))
                }
            }
            break;
        }
        return inputFields;
    }

    buildEditCtrl(param, name, key) {
        // validation of edit fields goes here
        let control = (<p key={key}>{param.label}
            <EditCtrlWValidator
                type="text"
                name={name}
                onChange={this.handleEditChange}
                maxLength="3"
                value={param.data}
                validationType="i"
                minValue=""
                maxValue=""
                errorMsg=""
            /></p> );
        return control;
    }

    buildTimeTable(param, validationInfo) {
        let values = this.state.advOptions.value1;
        let tableMarkup = [];
        let dowLabels = [ STRs.dowSun, STRs.dowMon, STRs.dowTues, STRs.dowWed, STRs.dowThur, STRs.dowFri, STRs.dowSat];
        // console.log('val info:', validationInfo)

        for (let i=0; i < _numDOW; i++) {
            let useDefault = values[i].length === 0;
            tableMarkup.push(
                <div className="time-table-row" key={"tt"+i}>
                    <div key={'d1tt'+i}>
                        <input type="checkbox" key={dowLabels[i]+i} value={i}
                                  onChange={this.toggleTimeCheckbox} checked={useDefault}>
                        </input>
                    </div>
                    <div key={'d2tt'+i}>{dowLabels[i]}</div>
                    <div key={'d3tt'+i}>
                        <TimeControl label="" timeHandler={this.handleTimeChange} controlKey={dowLabels[i]+"-tckk"+i}
                                     time={(useDefault) ? validationInfo.value1[i] : values[i]}
                                     timeId={i} />
                    </div>
                    <div key={'d4tt'+i}>{Utils.timeDataToDisplayString(Utils.strTimeToTimeData(validationInfo.value1[i]))}</div>
                </div>)
        }

        return (<div key="the-time-table">
            <div key="t1" className="time-table">
                <div key="label">{param.label}</div>
                <div className="time-table-headerrow" key="tth">
                    <div key="d1">{STRs.useDefault}</div>
                    <div key="d2">{STRs.dayOfWeek}</div>
                    <div key="d3">{STRs.customTime}</div>
                    <div key="d4">{STRs.defaultTime}</div>  
                </div>
                {tableMarkup}
            </div>
            </div>
        );
    }
    
    build_P_TP_markup(params, validationInfo) {
        const param = params[1];
        const dataValue = (typeof(param.data) === 'string') ? Number(param.data) : param.data;
        let options = [];
        let option = undefined;
        const validation = validationInfo.validation.toUpperCase();
        let percent = (validation === "P") ? "%" : "";
        let value;

        for (let min=validationInfo.min, stop=validationInfo.max; min <= stop; min += validationInfo.inc ) {
            if (validation === "TP") {
                value = Math.floor(min);
                if (value !== min) {
                    value = value + ":" + 60*(min-value);
                } else {
                    value = min + ":00"
                }
            } else {
                value = min+percent;
            }

            option = <option value={min} key={min} >{value}</option>;
            options.push(option);
        }
        const markup = (
            <div key="2">
                <div key="2a">
                    {param.label} <select id={this.state.advOptions.advOption} title={this.state.advOptions.value1+percent} 
                        onChange={ (e) => {this.handlePercentageChange(e.target.value)}} 
                        selected={dataValue} value={dataValue}>
                    {options}
                </select>
                </div>
            </div>
        );

        return markup;
    }

    render() {
        let strings = this.state.strings;
        let validationInfo = this.state.validationInfo;
        let currentAOData = this.state.advOptions;
        let params = [];
        let count = 0;

        let descriptions;
        descriptions = strings.descriptions.map(description => {
            return <p key={'desc'+count++}>{description}</p>
        });

        params.push({label: strings.mpyuOption, data: validationInfo.value1});
        params.push({label: strings.label1, data: currentAOData.value1});
        if (strings.label2.length > 0) {
            params.push({label: strings.label2, data: currentAOData.value2});
        }

        params = this.makeDataHandler(params, validationInfo, currentAOData.useDefault);

         return (
            <div className="nobottom-margin">
                {descriptions}
                {params}
            </div>
        );
    }
}

export default AdvancedOptions;
