/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */
import React, { Component } from 'react';
import PatientDataStorage from '../DataService/DataService';
import PatientHeader from '../shared/PatientHeader';
import { rxEvtReportStrings } from '../shared/strings';
import LoadingSpinner from '../shared/lib/LoadingSpinner';
import Utils from '../shared/Utilities';
import {INACTIVITY_TIMEOUT} from "../shared/constants";

class RxDeviceDataReport extends Component {
    constructor(props) {
        super(props);
        let eventDate = props.location.state.date;
        let dateTime = Utils.convertISO8601wTZToTimeData(eventDate, true);

        this.state = {history: props.history, initDone: false,
            error: false,
            caregiverId: props.location.state.caregiverId,
            patientId: props.location.state.patientId,
            eventDate: eventDate,
            eventDateTime: dateTime,
            rxData: undefined,
            timeoutId: -1,
        };
        this.handlePatientData = this.handlePatientData.bind(this);
        this.handleRxData = this.handleRxData.bind(this);
    }

    componentDidMount() {
        let {caregiverId, patientId, eventDate} = this.state;

        PatientDataStorage.getPatientAsync(caregiverId, patientId, this.handlePatientData);
        PatientDataStorage.getRxDeviceData(caregiverId, patientId, eventDate, 1, this.handleRxData);
    }

    componentWillUnmount() {
        console.log('componentWillUnmount: RxDeviceData');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
    }

    updateTimeout() {
        console.log('RxDeviceData report timeout update');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
        let timeoutId = setTimeout( () => {
            Utils.cleanupGoToLogin(this.state.history, true);
        }, INACTIVITY_TIMEOUT);

        PatientDataStorage.keepAlive();
        return timeoutId;
    }

    handlePatientData(pData, success) {
        if (success === 200) {
            let state = {patientData: pData};
            if (this.state.rxData) {
                state.initDone = true;
            }

            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    handleRxData(rxData, success) {
        if (success === 200) {
            let state = {rxData: rxData};
            if (this.state.patientData) {
                state.initDone = true;
            }

            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    patientReset(status) {
        if (status === 401) {
            if (!this.state.error) {
                this.setState({error: true});
                Utils.cleanupGoToLogin(this.state.history);
            }
        } else {
            alert('error');
        }
    }

    buildRxTable(rxData) {
        let rows = rxData.deviceData.map((rxDevice, i) => {
            let dates = [];
            let devNames = [];
            let dt = Utils.convertISO8601wTZToTimeData(rxDevice.devices[0].time, true);
            let displayDate = Utils.timeDataToDisplayDateTime(dt);
            let devName = rxDevice.devices[rxDevice.devices.length-1].deviceName;

            for (let j=0, len=rxDevice.devices.length; j < len; j++) {
//                let dev = rxDevice.devices[i];
                if (j === 0) {
                    dates.push(displayDate);
                    devNames.push(devName);
                } else {
                    dates.push(" ");
                    devNames.push(" ");
                }
            }

            let dateCell = dates.map((date, dt) => {
                let k = "dv"+i+"d"+dt;
                return (<div key={k}>{date}</div>)
            });
            let devNameCell = devNames.map((devName, dn) => {
                let k = "dv"+i+"d"+dn;
                return (<div key={k}>{devName}</div>);
            });
            let dataCell = rxDevice.data.map( (devData, dat) => {
                let k = "dv"+i+"d"+dat;
                let data = devData.data;
                if (! isNaN(+ data)) {
                    let numf = parseFloat(data);
                    let numi = parseInt(data,10);
                    devData.data = (numf === numi) ? numi : numf.toFixed(2);
                }
                return (<div key={k}>{devData.description}&nbsp;{devData.data}</div>)
            });

            return (
                <tr key={i}>
                    <td>{devNameCell}</td>
                    <td>{dateCell}</td>
                    <td>{dataCell}</td>
                </tr>
            )
        });

        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>{rxEvtReportStrings.deviceName}</th>
                        <th>{rxEvtReportStrings.date}</th>
                        <th>{rxEvtReportStrings.rxData}</th>
                    </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    render() {
        let {patientData: pData, initDone, rxData} = this.state;
        //let pData = this.state.patientData;
        let spinner = (initDone) ? "" : (<div><LoadingSpinner /></div> );
        let headerText = rxEvtReportStrings.rxHeader;
        // let headerText = rxEvtReportStrings.rxHeader + reportingDate.date;
        let header = (pData) ? (
            <PatientHeader patientName={pData.patientName}
                           patientImage={pData.patientImage}
                           patientState={pData.patientState}
                           bodyContent={headerText}
                           history={this.state.history}
            />
        ) : "";

        let tbl = (initDone) ? this.buildRxTable(rxData) : "";

        return (
            <div id="rxEventReport">
                {header}
                <div>{rxEvtReportStrings.rxHeader}</div>
                {spinner}
                {tbl}
            </div>
        )
    }
}

export default RxDeviceDataReport;
