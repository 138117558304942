/* eslint-disable react/prop-types */
/*******
 * Copyright 2017 - EmPowerYu, inc.
 *
 */
import React, { Component } from 'react';

import PatientCards from './PatientCards';
import Utils from '../shared/Utilities';
// import { URLS } from '../shared/urls';
import CaregiverServices from "../DataService/CareGiverService";

class Home extends Component {
    constructor(props) {
        super(props);
        let { history } = this.props;
        let caregiverId; // = props.location.state.caregiverId; //Utils.getCookie("caregiverId");
        if (! caregiverId) {
            const caregiverInfo = CaregiverServices.getLoginInfo();
            caregiverId = caregiverInfo.caregiverId;
        }

        if (caregiverId === undefined || caregiverId === null) {
            Utils.cleanupGoToLogin(history);
            return undefined;
        }
        this.state = {caregiverId: caregiverId, history: history};
    }
    render() {
        let { history, caregiverId } = this.state;
        let markup;

        if (caregiverId === undefined || caregiverId === null) {
            console.log("home: No valid caregiver");
            markup = false;
            Utils.cleanupGoToLogin(history);
        } else {
            markup = (
                <div className="App">
                    <PatientCards caregiverId={caregiverId} history={this.state.history} />
                </div>
            );
        }

        return markup;
    }
}

export default Home;
