/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LabeledEditField extends Component {
    constructor(props) {
        super(props);

        let label = props.label;
        if (! label) {
            console.log("LabeledEditField: missing 'label' attribute.");
            label = "Missing label"
        }

        let handler = props.handler;
        if (! handler) {
            console.log("LabeledEditField: missing 'handler' attribute.");
            handler = function() {
                alert('LabeledEditField: missing onchange handler!')
            }
        }

        let id = props.fid;
        if (! id) {
            console.log("LabeledEditField: missing 'fid' attribute.");
            id = "missingId";
        }

        let maxlength = props.maxlength;
        if (! maxlength) {
            console.log("LabeledEditField: missing 'maxlength' attribute.");
        }

        let password = !!props.password;
        let focus = !!(props.focus && (props.focus === true));
        let err = (props.err) ? props.err : false;

        this.state = {showLabel: false, label: label, id: id, maxlength: maxlength,
            focus: focus, err: err, handler: handler, password: password, handleEnter: props.handleEnter};

        this.handleEnter = this.handleEnter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.focus = this.focus.bind(this);
    }

    componentDidUpdate(prevProps) {
        let newState = {};
        let update = false;
        if (prevProps.focus !== this.props.focus) {
            newState.focus = this.props.focus;
            update = true;
        }

        if (prevProps.err !== this.props.err) {
            newState.err = this.props.err;
            update = true;
        }

        if (update) {
            this.setState(newState);
            this.focus();
        }
    }

    focus() {
        // Explicitly focus the text input using the raw DOM API
        this.textInput.focus();
    }

    handleEnter(e) {
        //alert('charCode: '+ e.charCode + 'key: '+e.key);
        if (e.key === "Enter" && this.state.handleEnter) {
            (this.state.handleEnter)();
        }
    }

    handleChange(e) {
        let showLabel = (e.target.value.length > 0);
        this.setState({ value: e.target.value, showLabel: showLabel});
        (this.state.handler)(e.target.value);
    }

    render() {
        let state = this.state;
        let css = state.showLabel ? "mpyShowLabel" : "";
        let inputType = state.password ? "password": "input";
        let inpClass = (state.err) ? "inputErr" : "";

        return (
            <div className="mpy-input" >
                <label htmlFor={state.id} className={css}>{state.label}</label>
                <input autoFocus={state.focus} type={inputType} tabIndex="0" id={state.id}
                       maxLength={state.maxlength} placeholder={state.label}
                       onKeyDown={this.handleEnter} onChange={this.handleChange} className={inpClass}
                       ref={(input) => { this.textInput = input; }} />
            </div>
        );
    }
}

LabeledEditField.propTypes = {
    label: PropTypes.string,
    handler: PropTypes.func,
    handleEnter: PropTypes.func,
    fid: PropTypes.string,
    maxlength: PropTypes.string,
    password: PropTypes.bool,
    focus: PropTypes.bool,
    err: PropTypes.bool,
}
export default LabeledEditField;

