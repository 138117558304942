/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, {Component} from 'react';

import { ValidicDeviceStrings as Strs } from "../shared/strings";
import Button from "react-bootstrap/Button";
import LocalStorageAPI from '../DataService/LStorage';
import PatientHeader from "../shared/PatientHeader";
import PatientDataStorage from "../DataService/DataService";
import Utils from "../shared/Utilities";
import {BEHAVIORSTATEICON} from "../shared/constants";
import blank from "../images/blank.gif";

class ValidicDevices extends Component {
    constructor(props) {
        super(props);

        let patientId = props.location.state.patientId;
        let caregiverId = props.location.state.caregiverId;

        this.state = {
            caregiverId: caregiverId,
            patientId: patientId,
            history: props.history,
        }
        this.handlePatientData = this.handlePatientData.bind(this);
    }

    componentDidMount() {
        // ask for our data from cache or the server
        PatientDataStorage.getPatientAsync(this.state.caregiverId, this.state.patientId, this.handlePatientData);
    }

    patientReset(status) {
        if (status === 401) {
            Utils.cleanupGoToLogin(this.state.history);
        } else {
            alert('error');
        }
    }

    // patient overview data arrived
    handlePatientData( pData, success ) {
        if (success === 200) {
            let state = {patientData: pData};
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    makeBehaviorIcon(behaviorState) {
        let behaviorStateIcon = BEHAVIORSTATEICON[behaviorState];
        return (
            <img src={blank} className={behaviorStateIcon} alt="patient activity status"/>
        );
    }

    render() {
        let {patientData: pData, history} = this.state;
        let header = <div> Hi Rich</div>;

        if (!pData) {
            header = "no header";
            console.log("PatientDetails: no pData or pDetails")
        } else {
            header = (
                <PatientHeader patientName={pData.patientName}
                               patientImage={pData.patientImage}
                               patientState={pData.patientState}
                               bodyContent={Strs.title}
                               icon={this.makeBehaviorIcon(pData.behaviorState)}
                               history={history}
                />
            )
        }

        return (
            <div>
                {header}
                <h2>{Strs.welcome}</h2>
                <div id="external-dev-info">
                    <p>{Strs.explanation}</p>
                    <p>{Strs.instruct}</p>
                </div>


                <Button onClick={() => {this.openValidicWindow()}}  size="sm" alt="device event button">
                    {Strs.buttonText}
                </Button>
            </div>
        );
    }

    openValidicWindow() {
        let vUrl = LocalStorageAPI.getPatientVUrl();
        window.open(vUrl, '_blank');
    }
}

export default ValidicDevices;
