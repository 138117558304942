/*******
 * Copyright 2017-2024 - EmPowerYu, inc.
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LabeledEditField from '../shared/lib/LabeledEditField';
import Button from 'react-bootstrap/Button';
import CaregiverDataServices   from '../DataService/CareGiverService';
import Utils from '../shared/Utilities';

import {LoginStrings} from "../shared/strings";
import {URLS} from '../shared/urls';

import WhiteLabel from './whiteLabelData';

const focusState = {
    unknown: 0,
    password: 1,
    username: 2,
    button: 3
};

const errorState = {
    noErr: 0,
    username: 1,
    password: 2,
    badUsernamePassword: 3
};

const messageStrings = [
    "",
    LoginStrings.missingUsername,
    LoginStrings.missingPassword,
    LoginStrings.badUsernamePassword
];

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {history: props.history, username: "", password: "", err: 0, focus: focusState.username};
        this.handleUsername = this.handleUsername.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUsernameEnter = this.handleUsernameEnter.bind(this);
        this.handlePwdEnter = this.handlePwdEnter.bind(this);
        this.userValidationCallback = this.userValidationCallback.bind(this);

        WhiteLabel.updateWhiteLabel();
        const whiteLabelData = WhiteLabel.get();
        document.title = whiteLabelData.name;
        document.querySelector("link[rel='shortcut icon']").href = whiteLabelData.favIcon;

    }

    handleUsername(e) {
        this.setState({username: e, err: !(e.length > 0), currentInput: "uname"});
    }

    handlePassword(e) {
        this.setState({password: e, err: !(e.length > 0), currentInput: "pwd"});
    }

    handleUsernameEnter() {
        this.setState({focus: focusState.password});
    }

    handlePwdEnter() {
        this.handleSubmit();
    }

    handleSubmit() {
        let state = undefined;
        if (! this.state.username.length) {
            state = {err: errorState.username, focus: focusState.username}
        }

        if (!state && (! this.state.password.length)) {
            state = {err: errorState.password, focus: focusState.password};
        }

        if (state) {
            this.setState( state );
        } else {
            CaregiverDataServices.validateUserCredentials(this.state.username, this.state.password, this.userValidationCallback);
        }
    }

    userValidationCallback(cgInfo, status) {
        let state = {err: errorState.noErr};
        if (status) {
            Utils.goToPage(this.state.history, URLS.home, {caregiverId: cgInfo.caregiverId});
        }  else {
            state.err = errorState.badUsernamePassword;
            state.focus = focusState.username;
            this.setState(state);
        }
    }

    render() {
        const usernameFocus = (this.state.focus === focusState.username);
        const passwordFocus = (this.state.focus === focusState.password);
        const errState = this.state.err;

        let messages = [messageStrings[this.state.err]];

        const whiteLabelData = WhiteLabel.get();

        return (
            <div className="login-padding">
                <h2>{LoginStrings.wlLoginHeader(whiteLabelData.name)}</h2>
                <div  id="loginPage">
                    <div>&nbsp;</div>
                    <div>
                        <LabeledEditField
                            maxlength="32"
                            label={LoginStrings.username}
                            fid="usrNameField"
                            handler={this.handleUsername}
                            focus={usernameFocus}
                            err={errState === errorState.username}
                            handleEnter={this.handleUsernameEnter} />


                        <LabeledEditField
                            maxlength="32"
                            label={LoginStrings.password}
                            fid="usrPwdField"
                            handler={this.handlePassword}
                            password={true}
                            focus={passwordFocus}
                            err={errState === errorState.password}
                            handleEnter={this.handlePwdEnter} />

                        <div id="err-msg">&nbsp;{messages}</div>
                        <div><Button onClick={this.handleSubmit}>Login</Button></div>
                    </div>
                </div>
            </div>
        )
    }
}

export class Logout extends Component {
    constructor(props) {
        super(props);
        this.state={history: props.history}
    }

    componentDidMount() {
        CaregiverDataServices.logout();
        Utils.goToPage(this.state.history, URLS.login, {});
    }

    componentDidUpdate() {
        CaregiverDataServices.logout();
        Utils.goToPage(this.state.history, URLS.login, {});
    }
    //
    // componentWillReceiveProps() {
    //     CaregiverDataServices.logout();
    //     Utils.goToPage(this.state.history, URLS.login, {});
    // }

    render() {
        return false;
    }
}

Login.propTypes = {
    history: PropTypes.any
}

Logout.propTypes = {
    history: PropTypes.any
}

export default Login;
