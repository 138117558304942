/*******
 * Copyright 2017 - EmPowerYu, inc.
 *
 */
import React, { Component } from 'react';
import spinner from '../../images/spinner.svg'

class LoadingSpinner extends Component {
    render() {
        return (
        <div className="spinner" >
            <img src={spinner} alt="spinner" />
        </div>
        );
    }
}

export default LoadingSpinner;