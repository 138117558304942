/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';

import {SYSTEM_CONSTANTS} from "../shared/constants";
import {OptionsPageString as STRs} from '../shared/strings';

// An Event Group is a heading with a collection of options (checkboxes) underneath it.  The group can be laid out with
// the options horizontally (each checkbox on the same line) or vertically (the checkboxes stacked on top of one another).
// In addition, each option can also have an advanced option associated with it.  The advanced is represented by a gear.
const EventGroup = ({ patientOptions, advancedOptions, sectionVisibilityInfo, handleOptionChange, handleEditAdvancedOptions, inline}) => 
{
    const advOptions = advancedOptions;
    const sectionAttrib = sectionVisibilityInfo.attrib;

    // start of utility functions
    const  handleOptionDetail = (e, eventAttrib) => {
        if (handleEditAdvancedOptions) {
            let eventGroupAttrib = sectionAttrib;
            (handleEditAdvancedOptions)(eventAttrib, eventGroupAttrib);
            e.preventDefault();
        }
        return false;
    }

    const toggleCheckbox = (e) => {
        let attrib=e.target.value;
        let eventGroupAttrib = sectionAttrib;
        let value = (patientOptions[eventGroupAttrib])[attrib];
        (handleOptionChange)(eventGroupAttrib, attrib, !value);
    }

    const buildInlineOption = (optionName, attrib, data) => {
        return (
                <label key={optionName}><input type="checkbox" value={attrib}
                            onChange={ toggleCheckbox } checked={data}/>&nbsp;{optionName}&nbsp;&nbsp;&nbsp;
                </label>
            )
    }

    const buildOption = (label, attrib, data, advOption) => {
        let optionName;

        if (advOption) {
            optionName =  (
                <span>{label}&nbsp;
                <span data={attrib} onClick={(e) => {
                    handleOptionDetail(e, attrib)
                }}> <i data={attrib} className="fa fa-cog" aria-hidden="true"></i></span></span>
            )
        } else {
            optionName = label;
        }

        return (
            <div key={label}>
                <label><input type="checkbox" value={attrib} onChange={toggleCheckbox} checked={data} />
                    &nbsp;{optionName}
                </label>
            </div>
        );
    }
    // end of utility functions

    if (! patientOptions) { return <div></div>; }

    let data = patientOptions[sectionAttrib];

    if (data === undefined) {
        // console.log('EventGroup.render: Bad category attribute passed in JSON.  The attribute "' + sectionVisibilityInfo.attrib +'" was not found.');
        return <div></div>;
    }

    let options = [];
    for (let i=0, len = sectionVisibilityInfo.items.length; i < len; i++)  {
        const optionInfo = sectionVisibilityInfo.items[i];
        const attrib = optionInfo.attribName;
        const value = data[attrib];

        if (value === undefined) {
            // console.log('EventGroup.render: Bad attribute passed in JSON.  looking for attrib = '+attrib +"'");
            continue;
        }

        const attribData = value ? value : false;
        const attribLabel = optionInfo.label;
        const advOption = (advOptions && advOptions[attrib] && optionInfo.advSetting !== false) ? advOptions[attrib] : undefined;
        // console.log(`eventGroup: attrib: ${attrib}; inline: ${inline}; data: ${attribData}`)

        if (inline) {
            options.push(buildInlineOption(attribLabel, attrib, attribData));
        } else {
            options.push(buildOption(attribLabel, attrib, attribData, advOption));
        }
    }

    return (
        <div className="optionsGroup" key={'evt'+sectionVisibilityInfo.label}>
            <span className="groupLabel">{sectionVisibilityInfo.label}</span>
            <div key={sectionVisibilityInfo.label + '1'} className="option-checkbox">
                {options}
            </div>
        </div>
    );
}

// all the Event Notification Options
class PatientOptionsDisplay extends Component {
    constructor(props) {
        super(props);
        let monitoringLevel = props.monitoringLevel;
        this.state = {
            title1: props.title1,
            title2: props.title2,
            monitoringLevel: monitoringLevel,
            inline: props.inline,

            patientOptions: props.patientOptions,
            advancedOptions: props.advancedOptions,
            visibilityMapping: props.visibilityMapping,

            handleOptionChange: props.handleOptionChange,
            handleEditOptionRanges: props.handleEditOptionRanges,
        };
    }

    componentDidUpdate(prevProps) {
        let state = {};
        if (prevProps.patientOptions !== this.props.patientOptions) {
            state.patientOptions = this.props.patientOptions;
        }
        if (prevProps.monitoringLevel !== this.props.monitoringLevel) {
            state.monitoringLevel = this.props.monitoringLevel;
        }
        if (Object.keys(state).length > 0) {
            this.setState(state);
        }
    }

    render() {
        if (!this.state.patientOptions) {return false;}
        const visibilityMap = this.props.visibilityMapping;

        let optionsList = visibilityMap.map((sectionVisibilityInfo) => {
            let monitoringLevel = this.state.monitoringLevel;
            let markup;

            // console.log(`PatientOptionsDisplay: inline = ${this.state.inline}; monitoring level = ${monitoringLevel}`, sectionVisibilityInfo, 
            //                                     'patientOptions: ', this.state.patientOptions)
            if (sectionVisibilityInfo.visible[monitoringLevel-1]) {
                markup = <EventGroup key={'eventGroup' + sectionVisibilityInfo.attrib}
                    patientOptions={this.state.patientOptions}
                    advancedOptions={this.state.advancedOptions}
                    monitoringLevel={this.state.monitoringLevel}
                    sectionVisibilityInfo={sectionVisibilityInfo}
                    handleOptionChange={this.state.handleOptionChange}
                    handleEditAdvancedOptions={this.state.handleEditOptionRanges}
                    inline={this.state.inline}
                />
            }
            return markup;
        });

        return (
            <div className="patientOptionDisplay">
                <div>{this.state.title1}<br/>
                    <span><i>{this.state.title2}</i></span></div>
                {optionsList}
            </div>
        );
    }
}

class PatientMonitoringLevel extends Component {
    constructor(props) {
        super(props);
        let userType = props.userType;
        let monitoringLevel = props.monitoringLevel;
        this.handleMLevelChange = this.handleMLevelChange.bind(this);

        this.state = {
            monitoringLevel,
            userType,
            handleLevelChange: props.handleLevelChange
        };
    }

    // monitoring level radio button change
    handleMLevelChange(e) {
        let monitoringLevel = Number(e.target.value, 10);
        (this.state.handleLevelChange)(monitoringLevel);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.monitoringLevel !== this.props.monitoringLevel) {
            this.setState({monitoringLevel: this.props.monitoringLevel});
        }
    }

    render() {
        let markup = '';
        if (this.state.userType === SYSTEM_CONSTANTS.userDemo) {
            let monitoringLevel = this.state.monitoringLevel;
            if (typeof monitoringLevel === "string") {
                monitoringLevel = parseInt(monitoringLevel, 10);
            }

            markup = (
                <div className="monitoring-levels">
                    <div>{STRs.monitoringLevel}</div>
                    <label><input name="monitorLevel" value="1" onChange={this.handleMLevelChange}
                           type="radio" checked={monitoringLevel === 1} /> {STRs.level1}</label><br />
                    <label><input name="monitorLevel" value="2" onChange={this.handleMLevelChange}
                        type="radio" checked={monitoringLevel === 2} /> {STRs.level2}</label><br />
                    <label><input name="monitorLevel" value="3" onChange={this.handleMLevelChange}
                        type="radio" checked={monitoringLevel === 3} /> {STRs.level3}</label>
                </div>
            );
        }

        return markup;
    }
}

export { PatientMonitoringLevel, PatientOptionsDisplay, EventGroup };
