import React, {Component} from 'react';
// import { HeaderFooterStrings } from '../shared/strings';
import WhiteLabel from './whiteLabelData';

class About extends Component {
    render() {
        const version = '2.902024';
        const whiteLabelData = WhiteLabel.get();

        // <br/><span>&copy;{HeaderFooterStrings.copyright}&trade;{HeaderFooterStrings.rights}</span>;
        
        return (
            <div><br/>
                <h2>About {whiteLabelData.name}</h2>
                <p>Version {version}</p>
            </div>
        );
    }
}

export default About;
