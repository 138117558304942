/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */
import React, { Component } from 'react';

class PatientHeaderBar extends Component {
    constructor(props) {
        super(props);
        let time = "";
        this.state = {
            patientName: props.patientName,
            patientState: props.patientState,
            time: time,
            supportBackBtn: (props.backBtnHandler) ? true : false,
            backBtnHandler: props.backBtnHandler
        };
        this.backHandler = this.backHandler.bind(this);
    }

    backHandler() {
        (this.state.backBtnHandler)();
    }

    render() {
        let state = this.state;
        const headerColors = {
            "0": "unknown",
            "1": "positive",
            "2": "alert",
            "3": "negative"
        };

        let header = "";
        try {
            let color = state.patientState.toString();
            let titleColor = "title-color-" + headerColors[color];
            // let backBtn = (this.state.supportBackBtn) ? (
            //     <span className={} onClick={this.backHandler}><i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;</span>
            // ) : "";


            let backBtn = "";
            if (this.state.supportBackBtn) {
                backBtn = (
                    <span className="back-button" onClick={this.backHandler}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;
                    </span>
                );
                titleColor = "pheader-bar-with-backbtn " + titleColor;
            } else {
                titleColor = "pheader-bar " + titleColor;
            }
            header = (
                <div className={titleColor}>
                    {backBtn}<span>{state.patientName}</span><span>{state.time}</span>
                </div>
            );
        }
        catch(e) {
            console.log("error rendering patient detail header");
        }

        return header;
    }
}

class PatientHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            patientName: props.patientName,
            patientImage: props.patientImage,
            patientState: props.patientState,
            bodyContent: props.bodyContent,
            icon: (props.icon !== undefined) ?  props.icon : "",
            supportBackBtn: (props.history) ? true : false,
            history: props.history
        };

        this.backHandler = this.backHandler.bind(this);
    }

    backHandler() {
        let history = this.state.history;
        history.goBack();
    }

    render() {
        const {patientName, patientState, patientImage, bodyContent, icon, supportBackBtn} = this.state;

        let headerBody = "";
        let headerBar = "";

//        let timestamp = Utils.convertTStampToTimeData(pDetails.timestamp);

        let activity = "";
        if (icon) {
            activity = (
                <div className="overall-activity">
                    {icon}
                </div>);
        }

        let supportBack = (supportBackBtn) ? this.backHandler : undefined;
        headerBar = (
            <PatientHeaderBar patientName={patientName} patientState={patientState} time="" backBtnHandler={supportBack}/>
        );

        //let eTime = Utils.timeDataToDisplayDateTime(pDetails.lastEventTime);
        headerBody = (
            <div className="pheader-body">

                <div className='patient-image'>
                    <img src={patientImage} alt={patientName}/>
                </div>
                <div className="patient-overview">
                    {bodyContent}

                </div>
                {activity}
            </div>
        );

        return (
            <div id="patientHeader">
                {headerBar}
                {headerBody}
            </div>
        );
    }
}

export default PatientHeader;
