/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */
import React, { Component } from 'react';
import PatientDataStorage from '../DataService/DataService';
import LocalStorageAPI from '../DataService/LStorage';
import HeatMap from './HeatMap';
import HMLegend from './HMLegend';
import moment from 'moment-timezone';

import PatientHeader from '../shared/PatientHeader';
import NextPrevNav from '../shared/lib/NextPrevNav';
import LoadingSpinner from '../shared/lib/LoadingSpinner';
import { URLS } from '../shared/urls';
import {HeatMapStrings} from '../shared/strings';
import Utils from "../shared/Utilities";

import Button from 'react-bootstrap/Button';
import {INACTIVITY_TIMEOUT, SYSTEM_CONSTANTS} from "../shared/constants";

const _NumDaysToLoad = 7;
class HeatmapDisplay extends Component {
    constructor(props) {
        super(props);
        let userType = LocalStorageAPI.getUserType();
        let {caregiverId, patientId, type, timezone } = props.location.state;

        //console.log("HeatmapDisplay props: "+JSON.stringify(props));

        // Create a date for today in the controller timezone, then set it back to midnight.
        let date = new moment.tz(timezone);
        date.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);

        this.state = {
            userType,
            error: false,
            caregiverId: caregiverId,
            patientId: patientId,
            mapType: type,
            heatmaps: [],
            patientData: undefined,
            legendData: undefined,
            initDone: false,
            history: props.history,
            date: date.valueOf(),
            today: date,
            nextEnabled: false,
            prevEnabled: false,
            timeoutId: -1
        };
        this.handlePatientData = this.handlePatientData.bind(this);
        this.handleHMData = this.handleHMData.bind(this);
        this.handleLegendData = this.handleLegendData.bind(this);
        this.patientReset = this.patientReset.bind(this);
        this.clickRxDevEventHandler = this.clickRxDevEventHandler.bind(this);
        this.handleDeviceEventsClick = this.handleDeviceEventsClick.bind(this);
        this.handleNextWeekClick = this.handleNextWeekClick.bind(this);
        this.handlePrevWeekClick = this.handlePrevWeekClick.bind(this);
    }

    componentDidMount() {
        let {caregiverId, patientId, date, mapType} = this.state;
        let state = undefined;  // need for legend which is only needed for activity.
                                // if we don't need legend data, add an object to state
                                // so we don't wait for it to come from the server.

        let mapMapType = { "activity": 0, "meals": 1, "sleeping": 2 };
        // ask for our data from cache or the server
        PatientDataStorage.getPatientAsync(caregiverId, patientId, this.handlePatientData);
        PatientDataStorage.getLegendData( caregiverId, patientId, mapMapType[mapType], this.handleLegendData );

        switch(mapType) {
            default:
            case 'sleeping':
            state = { hmDataLoader: PatientDataStorage.getSleepHMapData};      // don't wait for legend data
            break;

            case 'meals':
            state = { hmDataLoader: PatientDataStorage.getMealsHMapData};      // don't wait for legend data
            break;

            case 'activity':
            state = { hmDataLoader: PatientDataStorage.getActivityHMapData};
            break;
        }
        (state.hmDataLoader)(patientId, date, _NumDaysToLoad, this.handleHMData);

        if (state) {
            this.setState(state);
        }
    }

    componentWillUnmount() {
        console.log('componentWillUnmount: HeatmapDisplay');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
    }

    updateTimeout() {
        console.log('heatmap display timeout update');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
        let timeoutId = setTimeout( () => {
            Utils.cleanupGoToLogin(this.state.history, true);
        }, INACTIVITY_TIMEOUT);

        PatientDataStorage.keepAlive();
        return timeoutId;
    }

    componentDidUpdate(prevProps) {
        let state = {};
        let changed = false;

        if (prevProps.patientData !== this.props.patientData) {
            state.patientData = this.props.patientData;
            changed = true;
        }

        if (prevProps.legendData !== this.props.legendData) {
            state.legendData = this.props.legendData;
            changed = true;
        }

        if (prevProps.hmData !== this.props.hmData) {
            state.hmData = this.props.hmData;
            state.nextEnabled = this.props.nextEnabled;
            state.prevEnabled = this.props.prevEnabled;
            changed = true;
        }

        if (prevProps.initDone !== this.props.initDone) {
            state.initDone = this.props.initDone;
            changed = true;
        }

        if (changed) {
            state.timeoutId = this.updateTimeout();
            this.setState(state);
        }
    }

    // patient overview data arrived
    handlePatientData( pData, success ) {
        if (success === 200) {
            let state = {patientData: pData};
            if (this.state.heatmaps && this.state.legendData) {
                state.initDone = true;
            }
            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    handleHMData(hmData, success) {
        let {date, today} = this.state;

        if (success === 200) {
            let state = {heatmaps: hmData};
            if (this.state.patientData && this.state.legendData) {
                state.initDone = true;
            }
            
            state.timeoutId = this.updateTimeout();
            state.prevEnabled = true;
            state.nextEnabled = (date !== today.valueOf());

            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    handleLegendData(legendData, success) {
        if (success === 200) {
            let devObj = {};
            let rxDevObj = {};
            let legend = {rxDevices: [], devices: []};

            for (let i=0, len=legendData.deviceData.length; i < len; i++) {
                let dev = legendData.deviceData[i];
                if (dev.deviceType === 1) {
                    legend.rxDevices.push(dev);
                    rxDevObj[dev.deviceId] = dev;
                } else {
                    legend.devices.push(dev);
                    devObj[dev.deviceId] = dev;
                }
            }

            legend.deviceObj = devObj;
            legend.rxDeviceObj = rxDevObj;

            let state = {legendData: legend};
            if (this.state.patientData && this.state.heatmaps.length > 0 ) {
                state.initDone = true;
            }

            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    patientReset(success) {
        if (success === 401) {
            if (!this.state.error) {
                this.setState({error: true});
                Utils.cleanupGoToLogin(this.state.history);
            }
        } else {
            alert('bad data from server');
        }
    }

    clickRxDevEventHandler(date) {
        let {caregiverId, patientId} = this.state;
        // We need to convert this datetime and timezone pair back to a
        // unix timestamp before handing it back the back end.
        let date_Moment = new moment.tz(date.datetime, date.tz);
        let timestamp = date_Moment.valueOf();
        Utils.goToPage(this.state.history, URLS.rxDeviceDetail, {caregiverId: caregiverId, patientId: patientId, date: timestamp});
    }

    handleDeviceEventsClick() {
        let {caregiverId, patientId} = this.state;
        Utils.goToPage(this.state.history, URLS.deviceDetail, {caregiverId: caregiverId, patientId: patientId});
    }

    handleNextWeekClick() {
        let { patientId, date } = this.state;
        let ts = new Date();
        ts.setTime(date);
        ts.setDate(ts.getDate() + _NumDaysToLoad);
        (this.state.hmDataLoader)(patientId, ts.valueOf(), _NumDaysToLoad, this.handleHMData);
        this.setState({date: ts.valueOf()});
    }

    handlePrevWeekClick() {
        let { patientId, date } = this.state;
        let ts = new Date();
        ts.setTime(date);
        ts.setDate(ts.getDate() - _NumDaysToLoad);
        (this.state.hmDataLoader)(patientId, ts.valueOf(), _NumDaysToLoad, this.handleHMData);
        this.setState({date: ts.valueOf()});
    }

    render() {
        let {patientData, legendData, heatmaps, initDone, nextEnabled, prevEnabled, mapType} = this.state;
        let pData = patientData;
        if (this.state.caregiverId === null) {
            Utils.cleanupGoToLogin(this.state.history);
            return undefined;
        }
        let spinnerTO = (initDone) ? "" : <LoadingSpinner/>;

        let headerTextAttrib1 = this.state.mapType + "HeaderContent1";
        let headerTextAttrib2 = this.state.mapType + "HeaderContent2";
        let headerText = <span><strong>{HeatMapStrings[headerTextAttrib1]}</strong><br />{HeatMapStrings[headerTextAttrib2]}</span>;
            //  This page will display the heatmap for {this.state.mapType}.</span>;
        let header = (pData) ? (
            <PatientHeader patientName={pData.patientName}
                           patientImage={pData.patientImage}
                           patientState={pData.patientState}
                           bodyContent={headerText}
                           history={this.state.history} />
        ) : "";

        let fHasLegend = (legendData && Object.keys(legendData).length > 0 && initDone);
        let legend = (fHasLegend) ?
            (<HMLegend rxDevices={legendData.rxDevices} devices={legendData.devices} mapType={mapType}/>) : "";

        let hmaps = [];
        if (initDone) {
            hmaps = heatmaps.map((hmap, i) => {
                return (
                    <HeatMap key={i} type={this.state.mapType}
                             hmapData={hmap} legendData={legendData} clickHandler={this.clickRxDevEventHandler}/>);
            });
        }

        let eventsBtn = "";
        let nextPrevBlock = "";
        if (initDone) {
            const showEventBtn = (this.state.userType === SYSTEM_CONSTANTS.userAdmin  || this.state.userType === SYSTEM_CONSTANTS.userDemo);
            eventsBtn = showEventBtn ? (
                <div>
                    <Button onClick={this.handleDeviceEventsClick}>{HeatMapStrings.deviceEvents}</Button>
                </div>
            ) : <span></span>;


            let nextPrevData = {
                nextHandler: this.handleNextWeekClick,
                prevHandler: this.handlePrevWeekClick,
                nextBtnEnabled: nextEnabled,
                prevBtnEnabled: prevEnabled,
                showPrevNext: true

            };

            let hmTitleAttrib = this.state.mapType+"Title";
            nextPrevBlock = (
                <NextPrevNav icon={this.state.mapType} title={HeatMapStrings[hmTitleAttrib]} cmdObj={nextPrevData} />
            );
        }

        return (
            <div id="HeatMapDisplay">
                {header}
                {spinnerTO}
                {nextPrevBlock}
                {legend}
                {hmaps}
                {eventsBtn}
            </div>
        )
    }
}

export default HeatmapDisplay;
