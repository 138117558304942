/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EditCtrlWValidator extends Component {
    constructor(props) {
        super(props);

        let attribs = {};
        let keys = Object.keys(props);
        for (let i=0, len=keys.length; i < len; i++) {
            attribs[keys[i]] = props[keys[i]];
        }

        if (! props.name) {
            console.log("EditCtrlWValidator: missing 'name' attribute.");
        }

        if (! props.onChange) {
            console.log("EditCtrlWValidator: missing 'onChange' attribute.");
        }

        if (! props.validationType) {
            console.log("EditCtrlWValidator: missing 'validationType' attribute.");
        }

        this.state = attribs;

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        if (this.props.onChange) {
            (this.props.onChange)(e);
        } else {
            console.log('EditCtrlWValidator.handleChange: no onChange handler supplied');
        }
        this.setState({value: e.target.value});
    }

    render() {
        if (this.props.value === undefined || this.props.value === null) {
            alert('null in EditCtrlWValidator')
        }
        
        return (
            <input type="text"
               name={this.props.name}
               onChange={this.handleChange}
               maxLength={this.props.maxLength}
               value={this.props.value}
            />
        );
    }
}

EditCtrlWValidator.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    validationType: PropTypes.any,
    maxLength: PropTypes.string,
    value: PropTypes.string,
}

export default EditCtrlWValidator;

