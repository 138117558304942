/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, {Component} from 'react';
import Utils from '../shared/Utilities';
import { BEHAVIORSTATEICON, CONCERNSTATUSICONS, CONNECTIONSTATUSICON } from '../shared/constants';
import { URLS } from '../shared/urls';
import blank from '../images/blank.gif';

import { HomePageStrings as STRs } from '../shared/strings';

import ENVIRONMENTAL from '../images/environment.svg';
import PHYSIOLOGY from '../images/physiology.svg';
import BEHAVIOR from    '../images/behavior.svg';

class TblViewItemDetail extends Component {
    constructor(props) {
        super(props);
        this.state = { patient: props.patient, caregiverId: props.caregiverId, history: props.history };
        this.handlePatientClick = this.handlePatientClick.bind(this);
    }

    handlePatientClick() {
        let patientId = this.state.patient.patientID;
        //let myURL = URLS.patientCardClick+'?cID='+this.state.patient.patientID;

        let location = {
            pathname: URLS.patientCardDetails,
            state: { patientId: patientId, caregiverId: this.state.caregiverId }
        };
        this.state.history.push( location );
    }

    render() {
        let patient = this.state.patient;
        let lastActivityString = Utils.calculateLastActivity(patient.lastActivityTime);
        let activity = (lastActivityString  === "") ?
            <strong>{STRs.notApplicable}</strong> : lastActivityString;
        let behaviorColor = (patient.patientState === 1 ) ? "bg-green" :
            (patient.patientState === 2) ? "bg-orange" : "bg-red";

        let physioIcon = (patient.monitoringLevel !== 1) ? CONCERNSTATUSICONS[patient.physiologyStatus] : "";
        let monitoringLevelLabel = <span></span>
        monitoringLevelLabel = (monitoringLevelLabel === undefined) ? <span>{patient.monitoringLevel}</span> : monitoringLevelLabel;

        let style = {width: '85px'};
        let tableRow = (
            <tr className="no-top-border" onClick={this.handlePatientClick}>
                <td className={behaviorColor}></td>
                <td  style={style}><img alt={STRs.tableData} className="pat-img" src={patient.patientImage} width="60"/></td>
                <td className="align-left"><span>{patient.patientName}</span></td>
                <td className="">{monitoringLevelLabel}</td>
                <td className="stateimg">
                    <img src={blank} className={CONNECTIONSTATUSICON[patient.connectedStatus]} alt={STRs.connectionStatus} width="40"/>
                </td>
                <td className="stateimg">
                    <img src={blank} className={BEHAVIORSTATEICON[patient.behaviorState]} alt={STRs.behavioralStatus} width="40"/>
                </td>
                <td className="stateimg">
                    <img src={blank} className={CONCERNSTATUSICONS[patient.behaviorStatus]} alt={STRs.behavioralStatus} width="40"/>
                </td>
                <td className="stateimg">
                    <img src={blank} className={physioIcon} alt={STRs.physiologyStatus} width="40"/>
                </td>
                <td className="stateimg">
                    <img src={blank} className={CONCERNSTATUSICONS[patient.environmentalStatus]} alt={STRs.environStatus} width="40"/>
                </td>
                <td className="">{activity}</td>
            </tr>
        );
        return tableRow;
    }
}

class TblViewDetail extends Component {
    constructor(props) {
        super(props);
        this.state={patientList: props.patientList, history: props.history, caregiverId: props.caregiverId};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientList !== this.props.patientList) {
            this.setState({patientList: this.props.patientList});
        }
    }

    render() {
        let {patientList, history, caregiverId} = this.state;

        let patients = patientList.map( patient => {
            return <TblViewItemDetail patient={ patient }  key={patient.patientID} caregiverId={caregiverId} history={history} />;
        });

        // [ML] Hide monitoring level
        let monitoringLevelLabel = <></>;
        monitoringLevelLabel = (monitoringLevelLabel === undefined) ? <>{STRs.level}</> : monitoringLevelLabel;

        return (
            <div className="panel with-scroll animated fadeInUp delay-1">
                <div className="panel-heading clearfix">
                    <h3 className="panel-title">
                        <span className="fa fa-home" />
                        &nbsp;{STRs.agingInPlace}
                    </h3>
                </div>
                <div className="panel-body" id="Goals_contents">
                    <table className="table">
                        <thead>
                        <tr className="sortable">
                            <th width="20px">&nbsp;</th>
                            <th className="align-left" colSpan="2"><strong>{STRs.fullName}</strong></th>
                            <th><strong>{monitoringLevelLabel}</strong></th>
                            <th><strong>{STRs.connection}</strong></th>
                            <th><strong>{STRs.behaviorState}</strong></th>
                            <th><img src={BEHAVIOR} alt={STRs.behavioralStatus} width="40px"/></th>
                            <th><img src={PHYSIOLOGY} alt={STRs.physiologyStatus} width="40px"/></th>
                            <th><img src={ENVIRONMENTAL} alt={STRs.environStatus} width="40px"/></th>
                            <th><strong>{STRs.lastActivity}</strong></th>
                        </tr>
                        </thead>
                        <tbody>
                        {patients}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

class TblViewSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {title: props.title, summaryData: props.summaryData};
    }

    // [ML] Refactor to hide monitoring level
    buildMonitoringLevelTable(summaryData) {
        return (
            <div className="row noborder">
                <table className="table noborder">
                <thead className="noborder">
                <tr className="noborder">
                    <th><strong>{STRs.level1}</strong></th>
                    <th><strong>{STRs.level2}</strong></th>
                    <th><strong>{STRs.level3}</strong></th>
                    <th><strong>{STRs.needingAttn}</strong></th>
                    <th><strong>{STRs.notConnected}</strong></th>
                </tr>
                </thead>
                    <tbody>
                    <tr className="no-top-border">
                        <td><span className="">{summaryData.level1}</span></td>
                        <td className="">{summaryData.level2}</td>
                        <td className="">{summaryData.level3}</td>
                        <td className="">{summaryData.needAttention}</td>
                        <td className="">{summaryData.notConnected}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        let summaryData = this.state.summaryData;
        let patientCount = summaryData.level1 + summaryData.level2 + summaryData.level3;

        // [ML] Hide monitoring level
        let mlData = <></>
        mlData = (mlData === undefined) ? this.buildMonitoringLevelTable(summaryData) : mlData;

        const dataDiv = (
            <div className="panel-body">
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <p>{STRs.numPatients(patientCount)}</p>
                            <p>{STRs.currentlyNeedsAttn} {summaryData.needAttention}</p>
                            {mlData}
                        </div>
                    </div>
                </div>
            </div>
        );
        return (
        <div className="panel animated fadeInUp">
            <div className="panel-heading clearfix">
                <h3 className="panel-title">
                    <span className="fa fa-asterisk" />
                    &nbsp;{this.state.title}
                </h3>
            </div>
            {dataDiv}
        </div>
        );
    }
}

class TableView extends Component {
    constructor(props) {
        super(props);
        this.state = { summaryData: props.summaryData, patientList: props.patientList,
            caregiverId: props.caregiverId, history: props.history};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientList !== this.props.patientList) {
            this.setState({patientList: this.props.patientList});
        }
    }

    render() {
        return (
            <div id="tableView">
                <div className="al-main">
                    <div className="al-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <TblViewSummary
                                        title={STRs.summary}
                                        summaryData={this.state.summaryData}/>
                                    <TblViewDetail patientList={this.state.patientList} caregiverId={this.state.caregiverId}
                                                   history={this.state.history}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TableView;
