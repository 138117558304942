/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */
import React, { Component } from 'react';
import PatientDataStorage from '../DataService/DataService';
import PatientHeader from '../shared/PatientHeader';
import { UnusualEvtReportStrings } from '../shared/strings';
import LoadingSpinner from '../shared/lib/LoadingSpinner';
import Utils from '../shared/Utilities';
import {INACTIVITY_TIMEOUT} from "../shared/constants";

class UnusualEventsReport extends Component {
    constructor(props) {
        super(props);
        this.state = {history: props.history, initDone: false, error: false, timeoutId: -1,
            caregiverId: props.location.state.caregiverId, patientId: props.location.state.patientId };
        this.handlePatientData = this.handlePatientData.bind(this);
        this.handleUnusualEventsData = this.handleUnusualEventsData.bind(this);
    }

    componentDidMount() {
        let {caregiverId, patientId} = this.state;

        PatientDataStorage.getPatientAsync(caregiverId, patientId, this.handlePatientData);
        PatientDataStorage.getUnusualEvents(caregiverId, patientId, Date.now(), 7, this.handleUnusualEventsData);
    }

    componentWillUnmount() {
        console.log('componentWillUnmount: Unusual Events Report');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
    }

    updateTimeout() {
        console.log(' Unusual Events Report timeout update');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
        let timeoutId = setTimeout( () => {
            Utils.cleanupGoToLogin(this.state.history, true);
        }, INACTIVITY_TIMEOUT);

        PatientDataStorage.keepAlive();
        return timeoutId;
    }

    handlePatientData(pData, success) {
        if (success === 200) {
            let state = {patientData: pData};
            if (this.state.uEventsData) {
                state.initDone = true;
            }

            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    handleUnusualEventsData(uEvents, success) {
        if (success === 200) {
            let state = {uEventsData: uEvents};
            if (this.state.patientData) {
                state.initDone = true;
            }

            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    buildUnusualEventTbl(uEventsData) {
        let rows = uEventsData.eventsList.map((event, i) => {
            let dateCell = Utils.convertISO8601wTZToTimeData(event.time);
            let descriptionCell = event.msg;
            let concernCell = event.status;

            return (
                <tr key={i}>
                    <td>{Utils.timeDataToDisplayDateTime(dateCell, true)}</td>
                    <td>{descriptionCell}</td>
                    <td>{concernCell}</td>
                </tr>
            );
        });

        return (
            <table className="table">
                <thead>
                <tr>
                    <th>{UnusualEvtReportStrings.date}</th>
                    <th>{UnusualEvtReportStrings.description}</th>
                    <th>{UnusualEvtReportStrings.concern}</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    patientReset(status) {
        if (status === 401) {
            if (!this.state.error) {
                this.setState({error: true});
                Utils.cleanupGoToLogin(this.state.history);
            }
        } else {
            alert('error');
        }
    }

    render() {
        let {patientData: pData, initDone, uEventsData} = this.state;
        let spinner = (initDone) ? "" : (<div><LoadingSpinner /></div> );
        let headerText = UnusualEvtReportStrings.unusualEvtsHeader;
        let header = (pData) ? (
            <PatientHeader patientName={pData.patientName}
                           patientImage={pData.patientImage}
                           patientState={pData.patientState}
                           bodyContent={headerText}
                           history={this.state.history}
            />
        ) : "";

        let tbl = (initDone) ? this.buildUnusualEventTbl(uEventsData) : "";

        return (
            <div id="rxEventReport">
                {header}
                {spinner}
                {tbl}
            </div>
        )
    }
}

export default UnusualEventsReport;
