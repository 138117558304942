/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';
import Utils from '../Utilities';
import {OptionsPageString as STRs} from '../strings';

class TimeControl extends Component {
    constructor(props) {
        super(props);
        this.handleTimeChange = this.handleTimeChange.bind(this);
    }

    handleTimeChange(value, type) {
        let timeData = Utils.strTimeToTimeData(this.props.time);
        switch (type) {
            case "hours": {
                let hours = Number.parseInt(value,10);
                timeData.hours = hours; //(hours < 10) ? "0" + hours : hours;
            } 
            break;

            case "min": {
                let min = Number.parseInt(value,10);
                timeData.min = min; 
            }
            break;

            default:
            case "ampm":
                timeData.ampm = value;
                break;
        }

        let time = Utils.timeDataToString(timeData);
        this.props.timeHandler(time, this.props.timeId);
    }

    render() {
        let timeData = Utils.strTimeToTimeData(this.props.time);
        let title = [ "12", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11" ];
        let minTitle = timeData.min.toString();
        let hoursOptions = [];
        let minOptions = [];
        let amPmOptions = [];

        for (let i=0; i < 12; i++) {
            let val = ('0' + (i+1)).slice(-2);
            hoursOptions.push(<option key={'h'+i}>{val}</option>)
        }

        minTitle = (timeData.min < 10) ? "0"+minTitle : minTitle;
        for (let i=0; i < 60; i += 5) {
            let val = (i < 10) ? "0" + i : i;
            minOptions.push(<option key={'m'+i}>{val}</option>);
        }


        let ampmName = [STRs.am, STRs.pm];
        for (let i=0; i < 2; i++) {
            amPmOptions.push(<option key={ampmName[i].toLowerCase()}>{ampmName[i]}</option>);
        }

        return (
            <div key={this.props.controlKey+"-time"}>{this.props.label}&nbsp;&nbsp;
                <select title={title[timeData.hours]} id="hours" key="hkey" onChange={(e) => {
                    this.handleTimeChange(e.target.value, "hours")}
                } value={title[timeData.hours]} >
                    {hoursOptions}
                </select>&nbsp;

                <select title={minTitle} id="min" key="mkey" onChange={(e) => {
                    this.handleTimeChange(e.target.value, "min")
                }} value={minTitle}>
                    {minOptions}
                </select>&nbsp;

                <select title={timeData.ampm.toUpperCase()} id="ampm" key="ampmKey" onChange={(e) => {
                    this.handleTimeChange(e.target.value, "ampm")
                }} value={timeData.ampm}>
                    {amPmOptions}
                </select>
            </div>
        )
    }
}

export default TimeControl;
