/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */
import React, { Component } from 'react';

import Utils from '../shared/Utilities';
import { DetailPageStrings as Strs} from '../shared/strings';
import PatientDataStorage   from '../DataService/DataService';
// import LocalStorageAPI from '../DataService/LStorage';
// import {BEHAVIORSTATEICON, SYSTEM_CONSTANTS} from '../shared/constants';
import { BEHAVIORSTATEICON, INACTIVITY_TIMEOUT } from '../shared/constants';
import { URLS }  from '../shared/urls';
import PatientHeader from '../shared/PatientHeader';
import LoadingSpinner from '../shared/lib/LoadingSpinner';

import blank from '../images/blank.gif';

import Button from 'react-bootstrap/Button';
import LocalStorageAPI from '../DataService/LStorage';

class DetailBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: props.patientId,
            caregiverId: props.caregiverId,
            title: props.title,
            icon: props.icon,
            data: props.details,
            handlerData: props.handlerData,
            history: props.history,
            timezone: props.timezone
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        let {history, handlerData, patientId, caregiverId, timezone} = this.state;
        Utils.goToPage( history, URLS.heatmapURL, {type: handlerData, patientId: patientId, caregiverId: caregiverId, timezone: timezone });
    }

    render() {
        let data = this.state.data;
        let detailBoxClass = "card-body " + this.state.icon;

        return (
            <div className="patient-card" onClick={this.handleClick}>
                <div className="card-header">
                    {this.state.title}
                </div>
                <div className={detailBoxClass}>
                    <div>{data.label1}{data.string1}</div>
                    <div>{data.label2}{data.string2}</div>
                </div>
            </div>
        );
    }
}

class UnusualEventBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            icon: props.icon,
            data: props.details,
            patientId: props.patientId,
            caregiverId: props.caregiverId,
            history: props.history
        };

        this.handleDetailClick = this.handleDetailClick.bind(this);
    }

    handleDetailClick() {
        let {history, patientId, caregiverId} = this.state;
        Utils.goToPage( history, URLS.unusualEvents, {patientId: patientId, caregiverId: caregiverId });
    }

    render() {
        let data = this.state.data;
        let items;

        if (data.length > 0) {
            let i=0;
            items = data.map( item => {
                let record;

                record = (
                    <div className="ua-record" key={"ue"+i++}>
                        <div className="ua-date">{Utils.timeDataToDisplayDateTime(item.time)}</div>
                        <div className="ua-msg">{item.msg}</div>
                    </div>
                );

                return record;
            });
        } else {
            items = (
                <div className="ua-record" key={"ue0"}>
                    <div className="ua-date"> </div>
                    <div className="ua-msg">{Strs.noUnusualActivity}</div>
                </div>
            )
        }

        return (
            <div className="patient-card" onClick={this.handleDetailClick}>
                <div className="card-header unusual-activity-header">
                    {this.state.title}
                </div>
                <div className="ua-card-body">
                    {items}
                </div>
                <br />
            </div>
        );
    }
}

class PatientDetails extends Component {
    constructor(props) {
        super(props);
        let patientId = props.location.state.patientId;
        let caregiverId = props.location.state.caregiverId;
        let timezone = props.location.state.timezone;

        this.state = {
            error: false,
            caregiverId: caregiverId,
            patientId: patientId,
            timezone: timezone,
            history: props.history,
            updatedDetails: false,
            timeoutId: -1,
        };

        this.handlePatientDetails = this.handlePatientDetails.bind(this);
        this.handleSettingsClick = this.handleSettingsClick.bind(this);
        this.handleBLevelClick = this.handleBLevelClick.bind(this);
        this.handleHomeLifeClick = this.handleHomeLifeClick.bind(this);
        this.handlePatientData = this.handlePatientData.bind(this);
        this.handleValidicClick = this.handleValidicClick.bind(this);
        this.handleLocAlertOpClick = this.handleLocAlertOpClick.bind(this);
    }

    componentDidMount() {
        // ask for our data from cache or the server
        PatientDataStorage.getPatientDetails(this.state.caregiverId, this.state.patientId, this.handlePatientDetails);
        PatientDataStorage.getPatientAsync(this.state.caregiverId, this.state.patientId, this.handlePatientData);
    }

    componentWillUnmount() {
        // console.log('componentWillUnmount: patientDetails');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
    }

    updateTimeout() {
        // console.log('patient details timeout update');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
        let timeoutId = setTimeout( () => {
            Utils.cleanupGoToLogin(this.state.history, true);
        }, INACTIVITY_TIMEOUT);
        PatientDataStorage.keepAlive();

        return timeoutId;
    }

    patientReset(status) {
        if (status === 401) {
            if (!this.state.error) {
                this.setState({error: true});
                Utils.cleanupGoToLogin(this.state.history);
            }
        } else {
            alert('error');
        }
    }

    // patient overview data arrived
    handlePatientData( pData, success ) {
        if (success === 200) {
            let state = {patientData: pData};
            if (this.state.patientDetails) {
                state.updatedDetails = true;
            }

            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    // patient detail arrived
    handlePatientDetails(patientDetails, success) {
         if (success === 200) {
            let state = {patientDetails: patientDetails};
            if (this.state.patientData) {
                state.updatedDetails = true;
            }

            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }
    handleHomeLifeClick() {
        let { history, caregiverId, patientId} = this.state;

        Utils.goToPage(history, URLS.homeLifeReport, {caregiverId, patientId});
    }

    handleSettingsClick() {
        let state = this.state;

        Utils.goToPage(state.history, URLS.patientAlertSetting,
            {caregiverId: state.caregiverId, patientId: state.patientId});
    }

    handleBLevelClick() {
        let state = this.state;

        Utils.goToPage(state.history, URLS.batteryLevels,
            {caregiverId: state.caregiverId, patientId: state.patientId, timezone: state.timezone});
    }

    handleValidicClick() {
        let state = this.state;

        Utils.goToPage(state.history, URLS.validicDeviceSupport,
            {caregiverId: state.caregiverId, patientId: state.patientId});
    }

    handleLocAlertOpClick() {
        let state = this.state;

        Utils.goToPage(state.history, URLS.locAlertOptions,
            {caregiverId: state.caregiverId, patientId: state.patientId});
    }

    timeDateDisplay(time) {
        return Utils.timeDataToDisplayDateTime(time);
    }

    makeBehaviorIcon(behaviorState) {
        let behaviorStateIcon = BEHAVIORSTATEICON[behaviorState];
        return (
                <img src={blank} className={behaviorStateIcon} alt="patient activity status"/>
            );
    }

    makeHeaderContent(patientDetails) {
        let eTime = Utils.timeDataToDisplayDateTime(patientDetails.lastEventTime);

        return (
            <div className="patient-overview">
                <div>Last Event Time: {eTime}</div>
                <div>Indoor Temperature: {patientDetails.houseTemperature}</div>
            </div>);
    }

    render() {
        let {patientId, caregiverId, timezone, patientData: pData, patientDetails: pDetails, history} = this.state;
        let data;
        let header;

        if (caregiverId === null) {
            Utils.cleanupGoToLogin(this.state.history);
            return undefined;
        }
        let sleepData, mealData, activityData;
        try {
            if (!Strs) {
                console.log("can't access strings")
            }

            if (this.state.updatedDetails) {
                sleepData = {
                    label1: Strs.toBed,
                    label2: Strs.wakeUp,
                    string1: this.timeDateDisplay(pDetails.bedTime),
                    string2: this.timeDateDisplay(pDetails.wakeUp)
                };
                mealData = {
                    label1: Strs.firstToday,
                    label2: Strs.mostRecent,
                    string1: this.timeDateDisplay(pDetails.firstMeal),
                    string2: this.timeDateDisplay(pDetails.mostRecentMeal)
                };
                activityData = {
                    label1: Strs.firstToday,
                    label2: Strs.mostRecent,
                    string1: this.timeDateDisplay(pDetails.firstActivity),
                    string2: this.timeDateDisplay(pDetails.mostRecentActivity)
                };
            }

            if (this.state.updatedDetails) {
                if (!pData || !pDetails) {
                    header = "no header";
                    console.log("PatientDetails: no pData or pDetails")
                } else {
                    header = (
                        <PatientHeader patientName={pData.patientName}
                                       patientImage={pData.patientImage}
                                       patientState={pData.patientState}
                                       bodyContent={this.makeHeaderContent(pDetails)}
                                       icon={this.makeBehaviorIcon(pData.behaviorState)}
                                       history={history}
                        />
                    )
                }

                // let buttons = (
                //     <React.fragment>
                //         <Button onClick={this.handleSettingsClick}>{Strs.settings}</Button>&nbsp;
                //         <Button onClick={this.handleBLevelClick}>{Strs.batteryLevels}</Button>
                //     </React.fragment>
                // );
                const buttons = [];

                // Replace with validic url check
                let vUrlPresent = LocalStorageAPI.getPatientVUrl(); 
                const validicBtn = (vUrlPresent) ?
                    (
                        <Button key='validicDevice' onClick={this.handleValidicClick}>{Strs.validicDevices}</Button>
                    ) : <span></span>;
                // validicBtn = <Button key='validicDevice' onClick={this.handleValidicClick}>{Strs.validicDevices}</Button>;

                buttons.push((
                        <React.Fragment key="settingsFrag">
                            <Button key="settings" onClick={this.handleSettingsClick}>{Strs.settings}</Button>&nbsp;
                            <Button key="homeLife" onClick={this.handleHomeLifeClick}>{Strs.homeLife}</Button>&nbsp;
                            {validicBtn}&nbsp;
                        </React.Fragment>));
                buttons.push((<><Button key="battLevels" onClick={this.handleBLevelClick}>{Strs.batteryLevels}</Button>&nbsp;</>));
                buttons.push((<Button key="locAlertOptions" onClick={this.handleLocAlertOpClick}>{Strs.locAlertDetails}</Button>));

                data = (
                    <div id="patient-detail">
                        {header}
                        <div className="patient-card">
                            <DetailBlock title={Strs.sleeping} icon="sleepIcon" details={sleepData}
                                         handlerData="sleeping" history={history} patientId={patientId} timezone={timezone} caregiverId={caregiverId} />
                            <DetailBlock title={Strs.meals} icon="mealsIcon" details={mealData}
                                         handlerData="meals" history={history}  patientId={patientId} timezone={timezone} caregiverId={caregiverId} />
                            <DetailBlock title={Strs.dailyActivity} icon="activityIcon" details={activityData}
                                         handlerData="activity"  history={history}  patientId={patientId} timezone={timezone} caregiverId={caregiverId} />
                            <UnusualEventBlock title={Strs.unusualActivity} details={pDetails.unusualEvents} caregiverId={caregiverId}
                                          patientId={patientId} timezone={timezone} history={history}/>
                        </div>

                        <div className="margin-bottom-16">{buttons}</div>
                    </div>
                );
            } else {
                data = <LoadingSpinner />;
            }
        }
        catch(e) {
            console.log('PatientDetails: error building details')
        }

        return data;
    }
}
export default PatientDetails;
