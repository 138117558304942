/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';
import {HeatMapStrings} from '../shared/strings';
//import Utils from '../shared/Utilities';

class DeviceList extends Component {
    constructor (props) {
        super(props);
        this.state = {devices: props.devices, mapType: props.mapType};
    }

    render() {
        let devices = this.state.devices;
        let devList = "";
        if (devices.length > 0) {
            devList = devices.map((dev, i) => {
                    let bgColor = "#" + dev.rgbColor;

                    let markup = (
                        <div className="legend-device-row" key={i}>
                            <div>{dev.deviceName}</div>
                            <div style={{background: bgColor}}> </div>
                        </div>
                    );
                return markup;
                }
            );
        }
        return <div>{devList}</div>;
    }
}
class DeviceLegend extends Component {
    constructor(props) {
        super(props);
        this.state = {devices: props.devices, mapType: props.mapType};
    }

    render() {
        let { mapType } = this.state;
        let devices = this.state.devices;
        let markup = "";
        let devMarkup = "";
        let activityLvlMarkup = "";

        if (devices.length > 0  || (mapType === "activity")) {
            if (devices.length > 0) {
                devMarkup = (
                    <div className="legendDevices">
                        <DeviceList devices={this.state.devices}/>
                    </div>
                );
            }

            if (mapType === "activity") {
                activityLvlMarkup = (
                    <div>
                        <div>
                            <span><i className="fa fa-arrow-left" aria-hidden="true"> </i>
                            &nbsp;{HeatMapStrings.activity}&nbsp;
                                <i className="fa fa-arrow-right" aria-hidden="true"> </i></span>
                        </div>

                        <div id="activity-levels">
                            <div>{HeatMapStrings.lo}</div>
                            <div> </div>
                            <div> </div>
                            <div> </div>
                            <div> </div>
                            <div> </div>
                            <div>{HeatMapStrings.hi}</div>
                        </div>
                    </div>
                );
            }

            markup = (<div id="legend-dev-blk">
                        {devMarkup}
                        {activityLvlMarkup}
            </div>)
        }

        return markup;
    }
}

class RxLegend extends Component {
    constructor(props) {
        super(props);
        this.state = {rxDevices: props.rxDevices, mapType: props.mapType};
    }

    render() {
        let rxDevices = this.state.rxDevices;
        let markup = "";

        if (rxDevices.length > 0) {
            markup = (
                <div id="legend-rxdev-blk">
                    <div className="legendDevices">
                        <DeviceList devices={this.state.rxDevices}/>
                    </div>
                    <div>
                        <div className="legend-device-row" key="goodRx">
                            <div>{HeatMapStrings.normal}</div>
                            <div className="rxPatternBgdGood rxBorderGood"> </div>
                        </div>
                        <div className="legend-device-row" key="badRx">
                            <div>{HeatMapStrings.abnormal}</div>
                            <div className="rxPatternBgdBad rxBorderBad"> </div>
                        </div>
                    </div>
                </div>
            );
        }

        return markup
    }

}

class HMLegend extends Component {
    constructor(props) {
        super(props);
        this.state = { mapType: props.mapType, devices: props.devices, rxDevices: props.rxDevices };
    }

    render() {
        let { rxDevices, devices, mapType } = this.state;
        let devLegend = (devices.length) ? <DeviceLegend devices={devices} mapType={mapType} /> : "";
        let rxDevLegend = (rxDevices.length) ? <RxLegend rxDevices={rxDevices}  mapType={mapType} /> : "";
        return (
            <div id="heat-legend">
                {devLegend}
                {rxDevLegend}
            </div>
        )
    }
}

export default HMLegend;
