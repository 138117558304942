import React, { Component } from 'react';

class AdminProfile extends Component {
    render() {
        return (
            <div>
                <h3>Admin Profile</h3>
            </div>
        );
    }
}

export default AdminProfile;