/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';
import NotificationOptions from './mpyOptions'
import Utils from '../shared/Utilities';
//import {URLS} from '../shared/urls';

class Options extends Component {
    constructor(props) {
        super(props);
        let caregiverId = (props.location.state.caregiverId) ? props.location.state.caregiverId : Utils.getCookie("caregiverId");
        let patientId = (props.location.state.patientId) ? props.location.state.patientId : Utils.getCookie("patientId");
        this.state = {caregiverId: caregiverId, patientId: patientId, history: props.history};
    }

    render() {
        let {caregiverId, patientId, history} = this.state;
        let markup;

        if (caregiverId.length && patientId.length) {
            markup = (
                <div className="App">
                    <NotificationOptions caregiverId={caregiverId} patientId={patientId}
                                        history={history} />
                </div>
            );
            return markup;
        } else {
            let msg = "";
            if (!caregiverId.length) {
                msg = "No valid caregiverId provided. "
            }
            if (!patientId.length) {
                if (msg.length > 0) { msg += '\n' }
                msg += "No valid patientId provide."
            }
            console.log(msg);
            alert(msg);
            //window.location.href = URLS.login;
        }
        return false;
    }
}

export default Options;
