/*******
 * Copyright 2017-2024 - EmPowerYu, inc.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

import {SYSTEM_CONSTANTS} from './constants';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { URLS } from './urls';
import LocalStorageAPI from '../DataService/LStorage';

import {NavbarStrings} from "./strings";
import CaregiverServices from "../DataService/CareGiverService";
import WhiteLabel from '../misc/whiteLabelData';

function MPYNavBar() {
    let history = useHistory();
    const wPath = window.location.href;
    console.log(wPath);
    const path = history.location.pathname;
    // console.log('current location = ' + history.location.pathname);

    let userType = LocalStorageAPI.getUserType();

    userType = (userType === null) ? undefined : userType;
    let loggedIn = false;
    if (userType) {
        loggedIn = true;
    }

    let currentNav;
    let optional;
    let whiteLabel = WhiteLabel.getWhiteLabelString();
    if (! whiteLabel) {
        whiteLabel = WhiteLabel.updateWhiteLabel()
    }
    const whiteLabelData = WhiteLabel.get()

    CaregiverServices.getLoginInfo();
    const username = CaregiverServices.getUsername();
    const organizationName = CaregiverServices.getOrganizationName();

    if (loggedIn && username && username.length > 0) {
        optional = (userType === SYSTEM_CONSTANTS.userAdmin) ? (
            <Nav.Link href={URLS.adminProfile} className={(path === URLS.adminProfile) ? "navbar-currentItem" : ''}>{NavbarStrings.admin}</Nav.Link>
        ) : <Navbar.Text></Navbar.Text>;

        currentNav = (
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href={URLS.home} className={
                        (path === URLS.home || path === URLS.login || path === URLS.logout) ? "navbar-currentItem" : ''}>{NavbarStrings.home}</Nav.Link>
                    {optional}
                    <Nav.Link href={URLS.caregiverProfile} className={(path === URLS.caregiverProfile) ? "navbar-currentItem" : ''}>{NavbarStrings.caregiver}</Nav.Link>
                    <Nav.Link href={URLS.about} className={(path === URLS.about) ? "navbar-currentItem" : ''}>
                        {NavbarStrings.about}
                    </Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link disabled><span className="cg-username">{username}</span></Nav.Link>&nbsp;&nbsp;
                    <Nav.Link disabled><span className="cg-username">{organizationName}</span></Nav.Link>&nbsp;&nbsp;
                    <Nav.Link href={URLS.logout} >
                        {NavbarStrings.logout}
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        )
    } else {
        currentNav = (
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href={URLS.login}>
                        {NavbarStrings.home}
                    </Nav.Link>
                    <Nav.Link href={URLS.about}>
                        {NavbarStrings.about}
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        )
    }

        const logo = whiteLabelData.logo;
    return (
        <div id="mpy-navbar">
            <Navbar className="navbar-default" collapseOnSelect expand="lg" bg="light" variant="light" fixed="top">
                {logo}
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                {currentNav}
            </Navbar>
        </div>
    )
}
// }

MPYNavBar.propTypes = {
    path: PropTypes.string,
    userType: PropTypes.string,
    loggedIn: PropTypes.bool
}

export default MPYNavBar;
