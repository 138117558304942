/* eslint-disable react/prop-types */
/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

import PatientDataStorage from '../DataService/DataService';
import PatientHeader from '../shared/PatientHeader';
import LoadingSpinner from '../shared/lib/LoadingSpinner';
import {DeviceEventReportStrings} from '../shared/strings';
import Utils from '../shared/Utilities';
import {INACTIVITY_TIMEOUT} from "../shared/constants";

const BASIC_EVENTS = true;
const DETAILED_EVENTS = false;
const ALL_EVENTS = -1;

class EventButtonGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            handler: props.onClick,
            buttonsInfo: props.buttonsInfo,
            currentEvent: props.currentEvent,
            showBasicEvents: props.showBasicEvents,
        };
        this.clickEventType = this.clickEventType.bind(this);
        this.clickDeviceEvent = this.clickDeviceEvent.bind(this);
    }

    componentDidUpdate(prevProps) {
        let fDidChange = false;
        let state = {};
        if (prevProps.showBasicEvents !== this.props.showBasicEvents) {
            state.showBasicEvents = this.props.showBasicEvents;
            fDidChange = true;
        }

        if (prevProps.currentEvent !== this.props.currentEvent) {
            state.currentEvent = this.props.currentEvent;
            fDidChange = true;
        }

        if (fDidChange) {
            this.setState(state);
        }
    }

    clickEventType(showBasicEvents) {
        (this.state.handler)(showBasicEvents, this.state.currentEvent);
    }

    clickDeviceEvent(deviceId) {
        (this.state.handler)(this.state.showBasicEvents, deviceId);
    }
    render() {
        let {buttonsInfo, currentEvent, showBasicEvents} = this.state;
        let basicStyle = (showBasicEvents === BASIC_EVENTS) ? "primary" : "secondary";
        let detailedStyle = (showBasicEvents === DETAILED_EVENTS) ? "primary" : "secondary";

        let buttons =(showBasicEvents) ? buttonsInfo.basicDevices : buttonsInfo.detailedDevices;

        let buttonsList = buttons.map((ebtnData, i) => {
            let style = (ebtnData.deviceId === currentEvent) ? "primary" : "secondary";
            //bsSize="small"
            return (
                <div key={"eb"+i}>
                    <Button onClick={() => {this.clickDeviceEvent(ebtnData.deviceId)}}  size="sm" variant={style} alt="device event button">
                        {ebtnData.deviceName}
                    </Button>&nbsp;
                </div>
            );
        }
        );

        return (
            <div className="eventBtnBlock">
                <div>{DeviceEventReportStrings.viewEvents}</div>
                <div>
                  <Button onClick={() => {this.clickEventType(BASIC_EVENTS)}}
                          variant={basicStyle} alt="basic events">{DeviceEventReportStrings.basicEvents}</Button>&nbsp;
                  <Button onClick={() => {this.clickEventType(DETAILED_EVENTS)}}
                          variant={detailedStyle} alt="detailed events">{DeviceEventReportStrings.detailedEvents}</Button>
                </div>
                <div>{DeviceEventReportStrings.chooseEventTypes}</div>
                <div id="eventBtnGroup">{buttonsList}</div>
            </div>
        );
    }
}

class EventTable extends Component {
    constructor(props) {
        super(props);
        this.state = {eventList: props.eventList, showBasicEvents: props.showBasicEvents};
    }

    componentDidUpdate(prevProps) {
        let oEvtList = prevProps.eventList;
        let evtList = this.props.eventList;
        if ((oEvtList && evtList && oEvtList.length !== evtList.length) ||
            (oEvtList.length > 0 && prevProps.eventList[0].deviceId !== evtList[0].deviceId) ||
            (prevProps.showBasicEvents !== this.props.showBasicEvents)) {
            this.setState({eventList: this.props.eventList, showBasicEvents: this.props.showBasicEvents});
        }
    }

    render() {
        let {eventList, showBasicEvents} = this.state;
        let header1 = (showBasicEvents) ? DeviceEventReportStrings.date : DeviceEventReportStrings.when;
        let header2 = (showBasicEvents) ? DeviceEventReportStrings.time : DeviceEventReportStrings.deviceId;
        let header3 = (showBasicEvents) ? DeviceEventReportStrings.sensor : DeviceEventReportStrings.description;
        let header4 = (showBasicEvents) ? DeviceEventReportStrings.event : DeviceEventReportStrings.value;

        let rows = eventList.map((event, i) => {
            let dateCell = Utils.convertISO8601wTZToTimeData(event.time, true);
            let cell1 =  (showBasicEvents) ? dateCell.datestring : Utils.timeDataToDisplayDateTime(dateCell);
            let cell2 = (showBasicEvents) ? Utils.timeDataToDisplayString(dateCell): event.deviceId;
            let cell3 = event.description;
            let cell4 = event.eventValue;

            return (
                <tr key={i}>
                    <td>{cell1}</td>
                    <td>{cell2}</td>
                    <td>{cell3}</td>
                    <td>{cell4}</td>
                </tr>
            );
        });

        return (
            <table className="table">
                <thead>
                <tr>
                    <th>{header1}</th>
                    <th>{header2}</th>
                    <th>{header3}</th>
                    <th>{header4}</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }
}

class DeviceEventsReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            history: props.history,
            initDone: false,
            showBasicEvents: BASIC_EVENTS,
            currentEvent: ALL_EVENTS,
            timeoutId: -1,

            caregiverId: props.location.state.caregiverId,
            patientId: props.location.state.patientId
        };

        this.handlePatientData = this.handlePatientData.bind(this);
        this.handleDeviceEventsData = this.handleDeviceEventsData.bind(this);
        this.handleDeviceEventsCats = this.handleDeviceEventsCats.bind(this);
        this.displayEvents = this.displayEvents.bind(this);
    }

    componentDidMount() {
        let {caregiverId, patientId} = this.state;

        PatientDataStorage.getPatientAsync(caregiverId, patientId, this.handlePatientData);
        PatientDataStorage.getDeviceEvents(patientId, BASIC_EVENTS, ALL_EVENTS, Date.now(), 7, this.handleDeviceEventsData);
        PatientDataStorage.getDeviceCategories(patientId, this.handleDeviceEventsCats);
    }

    componentWillUnmount() {
        console.log('Device Events Report componentWillUnmount '+Date().toString());
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
    }

    updateTimeout() {
        console.log('Device Events Report timeout update '+Date().toString());
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
        let timeoutId = setTimeout( () => {
            Utils.cleanupGoToLogin(this.state.history, true);
        }, INACTIVITY_TIMEOUT);

        PatientDataStorage.keepAlive();
        return timeoutId;
    }

    handlePatientData(pData, success) {
        if (success === 200) {
            let state = {patientData: pData};
            if (this.state.devEvents && this.state.devEventsCat) {
                state.initDone = true;
            }

            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    handleDeviceEventsData(devEvents, fIsBasicEvents, success) {
        if (success === 200) {
            setTimeout(function() {
                // let d = performance.now();

                let devEvents = this.state.devEvents;
                let devEventsObj = {};
                for (let i=0, len=devEvents.length; i < len; i++) {
                    let dev = devEvents[i];
                    if (devEventsObj[dev.deviceId]) {
                        devEventsObj[dev.deviceId].push(dev);
                    } else {
                        devEventsObj[dev.deviceId] = [ dev ];
                    }
                }
                this.setState({devEventsObj: devEventsObj});
                // let d1 = performance.now();
                // console.log("Milliseconds for array to obj conversiohn: "+ (d1 - d));
            }.bind(this), 100);

            let state = {devEvents: devEvents, showBasicEvents: fIsBasicEvents };
            if (this.state.patientData  && this.state.devEventsCat) {
                state.initDone = true;
            }

            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    handleDeviceEventsCats(devCategories, devCatNamesById, success) {
        if (success === 200) {
            devCategories.basicDevices.unshift({"deviceId": -1, "deviceName": DeviceEventReportStrings.all});
            devCategories.detailedDevices.unshift({"deviceId": -1, "deviceName": DeviceEventReportStrings.all});
            devCatNamesById.basicDevices["-1"] = DeviceEventReportStrings.all;
            devCatNamesById.detailedDevices["-1"] = DeviceEventReportStrings.all;
            let state = {
                devEventsCat: devCategories,
                devCatNamesById: devCatNamesById,
                currentEvents: ALL_EVENTS};

            if (this.state.patientData  && this.state.devEvents) {
                state.initDone = true;
            }
            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(success);
        }
    }

    patientReset(status) {
        if (status === 401) {
            if (!this.state.error) {
                this.setState({error: true});
                Utils.cleanupGoToLogin(this.state.history);
            }
        } else {
            alert('error');
        }
    }

    displayEvents(fBasicEvt, newEventId) {
        let state = {};
        if (this.state.showBasicEvents !== fBasicEvt) {
            newEventId = ALL_EVENTS;
            state.devEvents = [];
            state.initDone = false;
            PatientDataStorage.getDeviceEvents(this.state.patientId, (fBasicEvt ? 1 : 0 ), ALL_EVENTS, Date.now(), 7, this.handleDeviceEventsData);
        }

        state.timeoutId = this.updateTimeout();
        state.showBasicEvents = (fBasicEvt ? BASIC_EVENTS : DETAILED_EVENTS);
        state.currentEvent = newEventId;
        this.setState( state );
    }

    render() {
        let {initDone, patientData: pData, devEventsCat, devCatNamesById,
            devEvents, devEventsObj, showBasicEvents, currentEvent} = this.state;

        let headerText = "Device Events data";
        let header = (pData) ? (
            <PatientHeader patientName={pData.patientName}
                           patientImage={pData.patientImage}
                           patientState={pData.patientState}
                           bodyContent={headerText}
                           history={this.state.history}
            />
        ) : "";

        let buttons = "";
        let spinner = "";
        let eventTbl = "";

        if (initDone) {
            buttons = (<EventButtonGroup showBasicEvents={showBasicEvents} currentEvent={currentEvent}
                                         buttonsInfo={devEventsCat} onClick={this.displayEvents}/>);

            let eventsList = (currentEvent === ALL_EVENTS) ? devEvents : devEventsObj[currentEvent];
            if (!eventsList) {
                eventsList = [];
            }

            let devName = (showBasicEvents) ? devCatNamesById.basicDevices[currentEvent] : "";
            eventTbl = <EventTable eventList={eventsList} currentEvent={currentEvent}
                                   showBasicEvents={showBasicEvents} devName={devName} />;
        } else {
            spinner = <LoadingSpinner/>;
        }

        return (
            <div id="deviceEventReport" >
                {header}
                {spinner}
                {buttons}
                {eventTbl}
            </div>
        );
    }
}

export default DeviceEventsReport;
