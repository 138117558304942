/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Form from 'react-bootstrap/Form';

import {PatientMonitoringLevel, PatientOptionsDisplay} from './OptionComponents';
import AdvancedOptions from './AdvancedOptions';
import PatientDataStorage from '../DataService/DataService';
import PatientHeader from '../shared/PatientHeader';
import LocalStorageAPI from '../DataService/LStorage';

import {
    setUserOptionDefaults,
    EVENT_VISIBILITY_AND_ATTRIB_MAPPING,
    DELIVERY_VISIBILITY_AND_ATTRIB_MAPPING,
    ADVANCED_OPTION_STRINGS, INACTIVITY_TIMEOUT,
    getAdvancedOptionsStrings
} from '../shared/constants';
import {URLS} from '../shared/urls';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

import spinner from '../images/spinner.svg';
import Utils from "../shared/Utilities";
import {OptionsPageString as STRs} from '../shared/strings';
import WhiteLabel from '../misc/whiteLabelData';

let _advancedOptionStrings = ADVANCED_OPTION_STRINGS;

class NotificationOptions extends Component {
    constructor(props) {
        super(props);

        const userType = LocalStorageAPI.getUserType();
        setUserOptionDefaults(userType);
        const monitoringLevel = 0;
        const defaults = PatientDataStorage.getDefaultOptions(monitoringLevel);

        const whiteLabel = WhiteLabel.getWhiteLabelString()
        _advancedOptionStrings = getAdvancedOptionsStrings(whiteLabel)

        this.state = {
            userType,
            defaults,
            history: props.history,
            patientId: props.patientId,
            caregiverId: props.caregiverId,
            monitoringLevel,
            optionsChanged: false,
            initComplete: false,
            submitStatusText: "",
            patientOptions: undefined,
            advancedOptions: undefined,     // all current state of all advanced options
            updatedAOAttributes: {},        // attributes of advanced options that have been changed
            timeoutId: -1,
        };
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLevelChange = this.handleLevelChange.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handleEditOptionRanges = this.handleEditOptionRanges.bind(this);
        this.handlePatientListUpdate = this.handlePatientListUpdate.bind(this);

        // for modal dialog
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.open = this.open.bind(this);
        this.initModal = this.initModal.bind(this);
        this.modalAoHandler = this.modalAoHandler.bind(this);
    }

    componentDidMount() {
        // get our data
        PatientDataStorage.getPatientOptions(this.state.caregiverId, this.state.patientId, this);
        PatientDataStorage.getPatientList(this.state.caregiverId, this.handlePatientListUpdate);
    }

    componentWillUnmount() {
        console.log('componentWillUnmount: Options');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
    }

    updateTimeout() {
        // console.log(' Options timeout update');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
        let timeoutId = setTimeout( () => {
            Utils.cleanupGoToLogin(this.state.history, true);
        }, INACTIVITY_TIMEOUT);
        PatientDataStorage.keepAlive();
        return timeoutId;
    }

    // callback for getPatientList
    handlePatientListUpdate(patientList, status) {
        if (status === 200) {
            const patientInfo = PatientDataStorage.getPatient(undefined, this.state.patientId);
            const monitoringLevel = patientInfo.monitoringLevel;
            let level = parseInt(monitoringLevel, 10) - 1;
            let updatedOptions = PatientDataStorage.getDefaultOptions(level);
            let state = {patientInfo, monitoringLevel, defaults: updatedOptions.mlevelOptionDefaults};

            if (this.state.patientOptions) {
                state.initComplete = true;
            }

            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(status);
        }
    }

    // callback for getPatientOptions
    handlePatientOptions(options, status) {
        if (status === 200) {

            let state = {patientOptions: options.patientOptions, aoValidationInfo: options.aoValidationInfo, advancedOptions: options.advancedOptions};

            if (this.state.patientInfo) {
                state.initComplete = true;
                state.submitStatusText = "";
            }

            state.timeoutId = this.updateTimeout();
            this.setState(state);
        } else {
            this.patientReset(status);
        }
    }

    // this is called by the promise when the submit completes
    handleUpdateOptions(success) {
        if (success === 401) {
            this.patientReset(success);
        } else if (success !== 200) {
            let timeoutId = this.updateTimeout();
            this.setState({submitStatusText: STRs.errorSubmittingOptions, optionsChanged: false, timeoutId});
            console.log('Monitoring Options:  Failed to save options.')
        } else {
            let timeoutId = this.updateTimeout();
            this.setState( { submitStatusText: STRs.done, optionsChanged: false, timeoutId } );
        }
    }

    // this function handles changing monitoring levels (which isn't really relevent anymore).  Anyway, the big issue is resetting
    // settings to match the new level.  Not such a bit deal if going to a higher monitoring level but a much bigger deal if going
    // to a lower level. 
    handleLevelChange(monitoringLevel) {
        let level = parseInt(monitoringLevel, 10) - 1;
        let updatedOptions = PatientDataStorage.getDefaultOptions(level);
        console.log(JSON.stringify(updatedOptions));
        const defaults = updatedOptions.mlevelOptionDefaults;
        const patientOptions = this.state.patientOptions;

        if (!patientOptions) {
            console.log('no patient options');
        } else {
            patientOptions.currentMonitoringLevel = monitoringLevel;
            const keys = Object.keys(defaults);
            for (let i=0, len=keys.length; i < len; i++) {
                const key = keys[i];
                if (patientOptions[key] !== undefined) {
                    patientOptions[key] = Object.assign({}, defaults[key]);
                }
            }
        }

        let state = {};
        if (level >= 0 && level < 3) {
            let timeoutId = this.updateTimeout();
            state = {monitoringLevel: monitoringLevel, 
                    defaults,
                    timeoutId,
                    patientOptions,
                    advancedOptions: updatedOptions.advOptions, 
                    optionsChanged: true, 
                    submitStatusText: ""};
            this.setState(state);
        } else {
            console.log('mpyOptions.handleLevelChange: bad monitoring level passed ('+monitoringLevel+').');
        }
    }

    handleSubmit() {
        let patientOptions = Object.assign({}, this.state.patientOptions);
        let advancedOptions = this.state.advancedOptions;
        let updatedAo = [];
        let updatedAoAttributes = this.state.updatedAOAttributes;
        for (let attrib in updatedAoAttributes) {
            let ao = advancedOptions[attrib];
            updatedAo.push({ advOption: attrib, value1: ao.value1, value2: ao.value2, useDefault: ao.useDefault ? "1" : "0" })
        }
        patientOptions.currentMonitoringLevel = parseInt(this.state.monitoringLevel, 10);
        patientOptions.advancedOptions = updatedAo;
        PatientDataStorage.setPatientOptions(this.state.caregiverId, this.state.patientId, patientOptions, this);
    }

    handleBackButton() {
        window.history.back();
    }

    goHome() {
        window.location.href = URLS.goHome;
    }

    patientReset(success) {
        if (success === 401) {
            if (!this.state.error) {
                this.setState({error: true});
                Utils.cleanupGoToLogin(this.state.history);
            }
        } else {
            alert("Error getting patient Options");
        }
    }

    handleOptionChange(eventGroupAttrib, attrib, value) {
        let patientOptions = this.state.patientOptions;
        (patientOptions[eventGroupAttrib])[attrib] = value;
        let timeoutId = this.updateTimeout();
        this.setState({patientOptions: patientOptions, optionsChanged: true, submitStatusText: "", timeoutId});
    }

    handleEditOptionRanges(optionAttrib, eventGroupAttrib) {

        let myStrs = _advancedOptionStrings[optionAttrib];
        let validationInfo = this.state.aoValidationInfo[optionAttrib];
        let currentData = this.state.advancedOptions[optionAttrib];
        this.initModal({
            advOptionAttrib: optionAttrib,
            eventGroupAttrib: eventGroupAttrib,
            currentData: currentData,
            strings: myStrs,
            validationInfo: validationInfo
        });
    }

    render() {
        let {initComplete, patientInfo, userType, caregiverId} = this.state;

        if (caregiverId === null) {
            Utils.cleanupGoToLogin(this.state.history);
            return undefined;
        }

        let headerText = "Notifications and Alerts";
        let header = (patientInfo) ? (
            <PatientHeader patientName={patientInfo.patientName}
                           patientImage={patientInfo.patientImage}
                           patientState={patientInfo.patientState}
                           bodyContent={headerText}
                           history={this.state.history}
            />
        ) : "";

        let modal = (this.state.showModal) ? this.buildModal() : "";
        let monitoringLevel = this.state.monitoringLevel;

        let disable = !this.state.optionsChanged; // disable until an option changes
        let submitStatus = this.state.submitStatusText;

        let markup;

        if (initComplete) {
            const monitorLevel = (<PatientMonitoringLevel monitoringLevel={monitoringLevel}
                    userType={userType}
                    handleLevelChange={this.handleLevelChange}/>);

            const notifications = (<PatientOptionsDisplay 
                patientOptions={this.state.patientOptions}
                advancedOptions={this.state.advancedOptions}
                monitoringLevel={monitoringLevel}
                inline={false}
                visibilityMapping={EVENT_VISIBILITY_AND_ATTRIB_MAPPING}
                handleOptionChange={this.handleOptionChange}
                handleEditOptionRanges={this.handleEditOptionRanges}
                title1={STRs.notificationsAndAlerts}
            />)
            
            const communications = (<PatientOptionsDisplay 
                patientOptions={this.state.patientOptions}
                monitoringLevel={monitoringLevel}
                inline={true}
                visibilityMapping={DELIVERY_VISIBILITY_AND_ATTRIB_MAPPING}
                handleOptionChange={this.handleOptionChange}
                title1={STRs.communicationSettings}
            />)

            markup = (
                <div id="notificationOptionsContainer">
                    {header}
                    <form>
                        <Container fluid>
                            <div id="notification-options">
                                {/*<h4>Monitoring Level  for {patientName}</h4> */}
                                {monitorLevel}
                                {notifications}
                                {communications}
                            </div>
                        </Container>
                        <Button variant="primary" className={disable ? 'disabled-cursor': ''} disabled={disable} onClick={this.handleSubmit}>{STRs.submit}</Button>
                        &nbsp;&nbsp;<span className="submit-status">{submitStatus}</span>
                    </form>
                </div>
            );
        } else {
            markup = (
                <div>
                    <div  id="notification-options">
                        <div className="spinner">
                            <img src={spinner} alt="spinner"/>
                        </div>
                    </div>
                </div>
            );
        }
        return (

            <div>
                {markup}
                {modal}
            </div>
        );
    }

    /************
     * Modal Dialog Handling code
     *
     * Call initModal with an object and the modal will be set up and launched.
     */
    initModal(modalAttributes) {
        let modalData = {
            strings: modalAttributes.strings,
            validationInfo: modalAttributes.validationInfo,
            advOptionType: modalAttributes.advOptionAttrib,
            currentAdvOptions: Object.assign({}, modalAttributes.currentData),
            eventGroupAttrib: modalAttributes.eventGroupAttrib
        };

        this.setState({showModal: true, modalData: modalData});
    }

    open() {
        this.setState({showModal: true});
    }

    cancel() {
        this.setState({showModal: false});
    }

    modalAoHandler(newAdvOption) {
        let modalData = this.state.modalData;
        modalData.currentAdvOptions = newAdvOption;
        // let timeoutId = this.updateTimeout();
        this.setState(modalData); //, timeoutId);
    }

    save() {
        let advancedOptions = this.state.advancedOptions;           // all AO
        let updatedAOAttributes = this.state.updatedAOAttributes;   // All AO that have changed

        let modalData = this.state.modalData;
        let attrib = modalData.advOptionType;
        let currentAdvOptions = modalData.currentAdvOptions;       // AO being modified
        let eventGroupAttrib =modalData.eventGroupAttrib;         // event group containing alert

        advancedOptions[attrib] = currentAdvOptions;
        updatedAOAttributes[attrib] = attrib;

        // let timeoutId = this.updateTimeout();
        this.setState({
            showModal: false, optionsChanged: true, submitStatusText: "", // timeoutId,
            advancedOptions: advancedOptions, updatedAOAttributes: updatedAOAttributes
        });
        this.handleOptionChange(eventGroupAttrib, attrib, true);
    }

    buildModal() {
        let modalData = this.state.modalData;
        if (!modalData.strings) {
            alert("bad strings?");
        }
        return (
            <div>
                <Modal id="myModal" show={this.state.showModal} size="lg" centered onHide={() => {this.cancel()}}>
                    <Modal.Header id="options-mheader" closeButton>
                        <Modal.Title>{modalData.strings.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AdvancedOptions advOptions={modalData.currentAdvOptions}
                                         validationInfo={modalData.validationInfo}
                                         strings={modalData.strings}
                                         aoHandler={this.modalAoHandler}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.save}>OK</Button> &nbsp; <Button onClick={this.cancel}>Cancel</Button>
                </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

NotificationOptions.propTypes = {
    history: PropTypes.any,
    patientId: PropTypes.string,
    caregiverId: PropTypes.string,
}

export default NotificationOptions


